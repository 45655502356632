.create_listing_wrapper {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    color: #25272D;
    margin-bottom: 20px;

    .listing_container {
        width: 100%;
        box-sizing: border-box;
        max-width: 546px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .create_listing_heading {
            font-size: 26px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
        }

        .upload_file_container {
            border: 2px dashed #E0E0E0;
            background: #FAFAFA;
            border-radius: 12px;
            height: 256px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 6px;
            cursor: pointer;


        }

        .files_preview_container {
            display: flex;

            .files_preview {
                display: flex;
            }
        }
    }

    .files_wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .file_container {
            width: 100%;
            height: 256px;
            gap: 6px;
            display: flex;
            flex-wrap: wrap;
            border: 2px dashed #E0E0E0;
            border-radius: 12px;
            justify-content: space-between;

            .upload_file_heading {
                font-size: 12px;
                font-weight: 500;
                line-height: 15px;
                text-align: center;
            }

            .upload_file_subheading {
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
                text-align: center;
                color: #8F939A;
            }

            .file_wrap {
                border-radius: 12px;
                background: #FAFAFA;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .uploaded_file {
                    width: 100%;
                    object-fit: cover;
                    height: 100%;
                    max-height: 252px;
                    border-radius: 12px;
                }
            }

            .delete_container {
                position: absolute;
                display: flex;
                top: 6px;
                right: 10px;
                cursor: pointer;
            }

            .container_file0 {
                flex-direction: column;
                gap: 6px;
                cursor: pointer;

                &.dragging {
                    border: 2px dashed #000;
                    background-color: #b8b8b86f;
                }
            }
        }

        .grid_empty {
            .container_file0 {
                width: 100%;
            }
        }

        .grid_first {

            .container_file0,
            .container_file1 {
                width: calc(50% - 6px);
            }
        }

        .grid_second {
            .container_file0 {
                width: 100%;
                height: calc(50% - 6px);
            }

            .container_file1,
            .container_file2 {
                width: calc(50% - 6px);
                height: calc(50% - 6px);
            }
        }

        .grid_third {

            .container_file0 {
                width: 100%;
                height: calc(50% - 6px);
            }

            .container_file1,
            .container_file2,
            .container_file3 {
                width: calc(33.33% - 8px);
                height: calc(50% - 6px);
            }
        }

        .grid_fourth {

            .container_file1,
            .container_file2 {
                width: calc(50% - 6px);
                height: calc(50% - 6px);
            }

            .container_file3,
            .container_file4,
            .container_file0 {
                width: calc(33% - 8px);
                height: calc(50% - 8px);
            }
        }

        .grid_fifth {

            .container_file1,
            .container_file2 {
                width: calc(50% - 6px);
                height: calc(50% - 6px);
            }

            .container_file5,
            .container_file4,
            .container_file3 {
                width: calc(33% - 8px);
                height: calc(50% - 8px);
            }
        }

        .files_info {
            display: flex;
            align-items: center;
            gap: 4px;
            background-color: #F3F3F3;
            padding: 10px 12px;
            border-radius: 10px;
            color: #8F939A;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            max-width: 350px;

        }
    }


}