.meeting_link_container {
  margin: 12px 0px 24px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  .header_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  .button_container {
    width: 100%;
    display: flex;
    gap: 12px;

    .button_wrap {
      min-width: 90px;
      text-align: center;
      padding: 7px 12px;
      gap: 6px;
      border-radius: 16px;
      border: 1px solid #8f939a;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #25272d;
      cursor: pointer;

      &:hover,
      &.selected {
        border: 1px solid #ee7830;
        color: #ffffff;
        background: #ee7830;
      }
    }
  }

  .meeting_link_form_wrap {
    .form_group {
      margin-bottom: 0;
    }

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
  }

  .heading_2 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #000000;
  }
}
