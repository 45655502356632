.compare_container {
    display: block;

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.compare_container_resp {
    display: none;

    @media screen and (max-width: 900px) {
        display: block;
    }
}

.resp_table {
    .header_resp {
        margin-top: 50px;
        margin-bottom: 34px;
        display: flex;
        justify-content: space-between;
        padding: 0px 8px;
        align-items: center;

        .member_heading {
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: #EE7830;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .guest_heading {
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: #25272D;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .content_comparison {
        padding: 8px 0px;
        border-bottom: 1.5px solid #F3F3F3;
        margin-bottom: 24px;

        .name_resp {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            color: #6C6D6F;
        }

        .feature {
            font-size: 12px;
            font-weight: 500;
            line-height: 32px;
            color: #25272D;
        }

    }

}