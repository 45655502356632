.sticky_footer_bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    border-top: 1px solid #DDDDDD;
    color: #25272D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 40px;
    z-index: 1300;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
    font-size: 12px;



    .sticky_footer_bar_data,
    .footer_bar_right {

        display: flex;
        align-items: center;
        gap: 30px;

        .footer_sticky_link {
            display: flex;
            align-items: center;
            gap: 8px;

            @media screen and (max-width: 900px) {
                display: none;
            }
        }

        .modified_link_effect_footer {
            color: #25272D;
            text-decoration: none;

            &:hover {
                color: #EE7830;
                text-decoration: underline;
            }
        }

    }




    @media screen and (max-width: 600px) {
        display: none;
    }



}

.modal_sticky_footer_data {
    color: #FFFFFF;
    padding: 60px 60px 0px 60px;
    width: 100%;
    display: flex;
    gap: 32px;
    justify-content: space-between;
    flex-wrap: wrap;

    .footer_quick {
        min-width: 160px;
    }

    .footer_partner {
        min-width: 230px;
    }

    .footer_locate {
        min-width: 260px;
    }

    .footer_follow {
        min-width: 285px;
    }

    .sticky_foot_list {
        display: flex;
        flex-direction: column;
        gap: 22px;


        li {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 12px;
            color: #fff;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                color: #EE7830;
                text-decoration: underline;
            }
        }

        .sticky_footer_heading {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;

        }

        .locate_outer_container {

            display: flex;
            flex-direction: column;
            gap: 20px;

            .icon_locate_container {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                color: #EE7830;
                text-decoration: underline;
            }
        }

        .social_icon_container {
            display: flex;
            gap: 8px;

        }

        .store_icon_container {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }


    .bottom_sticky_footer_data {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 18px;
        margin-top: -30px;
    }

    .footer_sticky_line {
        width: 100%;
        background: #F3F3F3;
        height: 1px;
    }

    .sticky_footer_bottom {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        // margin-top: -18px;
        padding-bottom: 28px;

        .sticky_footer_bottom_right {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                color: #EE7830;
                text-decoration: underline;
            }
        }

    }

}

.close_sticky_footer {
    position: absolute;
    top: -70px;
    left: calc(50% - 28px)
}