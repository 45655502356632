.dob_container_wrap {
    .title_dob {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #25272D;
        margin-bottom: 16px;
    }

    .dob_input_container {
        display: flex;
        width: 100%;
        gap: 12px;
    }
}

.custom_select_wrap {
    position: relative;


    .dob_dropdown_container {
        position: absolute;
        box-sizing: border-box;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 101;
        border-radius: 10px;
        max-height: 120px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 4px;

        &::-webkit-scrollbar {
            width: 2px;
        }

        .drop_options {
            padding: 2px 12px;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #25272D;

            &:hover {
                background-color: #F6F6F7;
                cursor: pointer;
            }

            &.selected_option {
                background-color: #ee7830;
                color: #fff;

            }
        }
    }

    .input_container_main {
        border: 1px solid #DCDCDC;
        background: #ffffff;
        border-radius: 10px;
        padding: 12px 11px;
        position: relative;
        display: flex;
        flex-direction: column;

        .input_type {
            width: 100%;
            border: none;
            outline: none;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #25272D;
            pointer-events: none;
        }

        .dob_input_label {
            margin-bottom: 2px;
            pointer-events: none;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            color: #25272D;
        }

        .dow_expand_icon {
            position: absolute;
            bottom: 12px;
            right: 12px;
            @media screen and (max-width: 480px) {
                right: 5px;
                background: white;
            }
        }
    }
}