.dialog_layout_modal {

    &.bottom_dialog {
        .MuiDialog-container {
            @media screen and (max-width:768px) {
                align-items: flex-end;
            }
        }

        .MuiPaper-root {
            border-radius: 12px;

            @media screen and (max-width:768px) {
                margin: 0px 0px 0px 0px;
                max-height: calc(100% - 0px);
                width: calc(100%);
                border-radius: 0px;
            }
        }
    }

    &.center_dialog {
        .MuiDialog-container {
            @media screen and (max-width:768px) {
                align-items: center;
            }
        }

        .MuiPaper-root {
            border-radius: 12px;

            @media screen and (max-width:768px) {
                margin: 20px;
                max-height: calc(100% - 40px);
                width: calc(100%);
                border-radius: 8px;
            }
        }
    }
}