.upcoming_card_main {
    display: flex;
    cursor: pointer;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.0392156863);
    border-radius: 12px;

    .left_booking_date_wrap {
        padding-right: 36px;
        border-right: 2px solid #EBEBEB;
        display: flex;
        gap: 8px;
        flex-direction: column;
        justify-content: center;

        .today_day {
            color: #6C6D6F;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        .today_date {
            color: #25272d;
            font-size: 24px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
        }
    }

    .right_booking_details_wrap {
        width: calc(100% - 86px);

        .inner_right_booking_wrap {
            display: flex;
            width: 100%;
            justify-content: space-between;
            gap: 12px;
            padding-left: 20px;

            .right_content_main {
                width: calc(100%);

                .view_btn_container {
                    width: fit-content;
                    min-width: 138px;
                }

                .heder_container_booking {
                    width: 100%;
                    color: #25272d;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    gap: 16px;

                    .title_header_booking {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: calc(100% - 10px);
                    }

                }

                .sub_details_event_type {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 16px;


                    .event_type {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        color: #25272d;

                        .special {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16px;
                            color: rgb(238, 118, 47);
                        }
                    }

                    .link_type_wrap {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        .location_text_main {
                            width: 80px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: #25272d;
                            color: #25272d;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                        }

                        .map_click {
                            color: #ee7830;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            text-decoration-line: underline;
                            cursor: pointer;
                        }

                        .meet_text {
                            color: #25272d;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            text-decoration-line: underline;
                            cursor: pointer;
                        }
                    }

                    .time_duration_wrap {
                        .time_text {
                            color: #25272d;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                        }
                    }
                }

                .action_status_wrap {
                    margin-top: 6px;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 16px;

                    .status_wrap {
                        border: 1px solid #DCDCDC;
                        background: #FFFFFF;
                        padding: 9px 10px;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 22px;
                        border-radius: 8px;
                        color: #25272d;

                        .special_processing {
                            color: #FF3B30;
                        }

                        .special_success {
                            color: #34C759;
                        }


                    }

                }

            }



        }
    }
}