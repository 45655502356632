.event_card_container {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #DCDCDC;
    background: #FFFFFF;
    padding: 12px 16px;

    margin-bottom: 20px;


    .simple_card_wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 12px 4px 14px 4px;
        cursor: pointer;

        .header_card {
            display: flex;
            justify-content: space-between;
            gap: 12px;

            .heading_1 {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: left;
                color: #25272D;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 90%;
            }

            .action_card {
                display: flex;
                align-items: center;
                gap: 8px;

                img {
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body_1 {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: #6C6D6F;

            display: flex;
            align-items: center;
            gap: 4px;

            div {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    .view_all {
        display: flex;
        justify-content: flex-end;
        align-items: center;


        .viw_btn {
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #25272D;
        }

        img {
            width: 16px;
            height: 16px;
        }
    }
}