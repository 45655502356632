.modal-section.zoom_cropper_modal_wrap {
    max-width: fit-content;
    max-height: 100%;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 12px;
    overflow: hidden;

    @media screen and (max-width:600px) {
        border-radius: 0px;
    }

    .zoom_cropper_inner_wrap {
        max-width: 90vw;
        width: 100%;

        @media screen and (max-width:600px) {
            max-width: 100%;
        }

        .zoom_cropper_image_container {
            position: relative;
            width: 90vw;
            height: 450px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
            }

            @media screen and (max-width:600px) {
                width: 100vw;
                height: calc(100dvh - 170px);
            }

        }

        .zoom_action_wrap {
            width: 100%;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;

            @media screen and (max-width:600px) {
                flex-direction: column-reverse;
                gap: 16px;
            }
        }
    }
}