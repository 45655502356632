.create_listing_main {
  .listing_heading_main {
    position: relative;
    font-size: 26px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #25272D;
    margin-bottom: 23px;

    @media screen and (max-width:600px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

  }

  .listing_type_container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    .listing_type_heading {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #25272D;
    }

    .listing_type_subheading {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #25272D;
    }

    .special_padding {
      margin-bottom: 8px;
    }



  }

  .listing_type_radio {
    img {
      cursor: pointer;
    }

    display: flex;
    gap: 8px;
    align-items: center;

    .listing_ype_label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #8F939A;
    }

    .listing_ype_label.active {
      color: #25272D;
    }
  }


}

.select_payout_btn {
  // border: 1px solid #8f939a;
  border: 1px solid #DCDCDC;
  width: 100%;
  padding: 18px 20px;
  border-radius: 10px;
  cursor: pointer;

  .select_payout_heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #EE7830;
  }

  .select_payout_details {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #8F939A;

    &.special {
      color: #25272D;
    }

  }
}

.reset-button {
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #ee7830;
  // margin-left: 10px;
  position: absolute;
  top: -20px;
  right: 0;
  width: 100px;

  @media screen and (max-width:600px) {
    right: 20px;
    top: -24px;
  }
}

.save_resp_draft {
  display: none;
  border: none;
  background: transparent;

  @media screen and (max-width:600px) {
    display: block;
  }

  position: absolute;
  right: 0px;
  top: calc(50% - 12px);
}

.new-schedule_modal {

  .mytabs {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #505155;

    .active {
      color: #25272D;
    }
  }

  .all-mid-part {
    padding: 0px 20px !important;
  }

  @media screen and (max-width:600px) {
    overflow-y: auto;
    min-height: calc(100dvh - 51px);
    margin-top: auto !important;
    max-height: calc(100dvh - 51px);
    border-radius: 0px !important;
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;

    .mytabs {
      gap: 24px !important;
    }

    .all-mid-part {
      padding: 0 20px !important;
    }

    .recurring_type {
      padding: 0;
      padding-bottom: 24px;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      li {
        width: 100%;
      }
    }

    .form_row {
      padding: 0px;
    }


    .all-mid-part {
      padding: 0px !important;
    }

    .all-button {
      margin-top: auto;
      padding: 0 !important;

    }
  }



}

.ps_container_wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;

  .ps_content_inner {
    width: calc(50% - 10px);
    &.full_width{
      width: 100%;
    }
  }

  @media screen and (max-width:768px) {

    flex-direction: column;
    gap: 16px;

    .ps_content_inner {
      width: 100%;
    }

  }
}