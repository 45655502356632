
.preview_schedule_dates_dialog_content {
    padding: 24px 80px !important;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        padding: 20px !important;
    }     
}

.custom_dialog_action{
    padding: 32px !important;
    justify-content: center !important;
    @media screen and (max-width: 768px) {
            padding: 20px !important;
        }
}