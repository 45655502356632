.user_outer_layer_main {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    min-height: calc(100dvh - 56px);
    display: flex;
    padding: 34px 28px 34px 50px;
    gap: 28px;
    justify-content: stretch;

    @media screen and (max-width:1024px) {
        flex-direction: column;
        padding: 0;

    }

    .user_left_wrap {
        width: 414px;

        @media screen and (max-width:1024px) {
            width: 100%;
        }

        .user_left_container {
            border: 1px solid #DCDCDC;
            border-radius: 12px;
            width: 100%;
            height: fit-content;

            @media screen and (max-width:1024px) {
                border: none;
                border-radius: 0px;
            }
        }

        .profile_cover_wrapper {
            position: relative;
            width: 100%;

            .custom_media {
                width: 100%;
                height: 198px;
                border-radius: 12px 12px 0px 0px;
                object-fit: cover;

                @media screen and (max-width:1024px) {
                    border-radius: 0px;
                }
            }

            .profile_image_wrap {
                width: 150px;
                height: 150px;
                position: absolute;
                border: 4px solid #fff;
                bottom: -75px;
                left: calc(50% - 75px);
                border-radius: 50%;
                box-shadow: 0px 4px 20px 0px #0000001F;
                object-fit: cover;

                @media screen and (max-width:600px) {
                    width: 110px;
                    height: 110px;
                    bottom: -55px;
                    left: calc(50% - 55px);
                }
            }

            .liked_btn {
                position: absolute;
                top: 24px;
                right: 62px;
                cursor: pointer;

                @media screen and (max-width:600px) {
                    top: 20px;
                    right: 68px;
                }
            }

            .flag_btn {
                position: absolute;
                top: 24px;
                right: 14px;
                cursor: pointer;

                @media screen and (max-width:600px) {
                    top: 20px;
                    right: 20px;
                }
            }
        }

        .profile_details_wrap {
            padding: 12px 18px;
            border-bottom: 2px solid #F3F3F3;

            .tag_wrapper {
                height: 66px;

                @media screen and (max-width:600px) {
                    height: 50px;
                }

                .user_type_tag {
                    background: #25272D14;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22.56px;
                    width: fit-content;
                    color: #EE7830;
                    padding: 2px 8px;
                    border-radius: 12px;
                    margin-left: auto;
                }
            }

            .mates_name {
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
                color: #25272D;
            }

            .gender_section {
                margin: 8px 0px;
                display: flex;
                gap: 44px;
                justify-content: center;
                align-items: center;

                .body_text {
                    display: flex;
                    width: 80px;
                    gap: 4px;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    color: #25272D;
                }
            }

            .distance {
                display: flex;
                gap: 4px;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
                color: #6C6D6F;
            }

            .actions {
                margin-top: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 18px;

                .primary_btn {
                    padding: 8px 22px;
                    background: #EE7830;
                    border: none;
                    border-radius: 8px;
                    cursor: pointer;
                    box-shadow: 0px 4px 32px 0px #00000014;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: center;
                    color: #fff;
                    transition: all 0.3s ease-in;
                    width: 162px;

                    &:hover {
                        background: #ee7930da;
                    }
                }

                .secondary_btn {
                    padding: 7px 22px;
                    background: #ffffff;
                    border: 1px solid #000000;
                    border-radius: 8px;
                    cursor: pointer;
                    box-shadow: 0px 4px 32px 0px #00000014;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: center;
                    color: #000000;
                    transition: all 0.3s ease-in;
                    width: 162px;

                    &:hover {
                        background: #00000022;
                    }
                }

                .trinary_btn {
                    padding: 8px 22px;
                    background: #F3F3F3;
                    border: none;
                    border-radius: 8px;
                    cursor: pointer;
                    box-shadow: 0px 4px 16px 0px #00000014,
                        0px -4px 16px 0px #00000014,
                        4px 0px 16px 0px #00000014,
                        -4px 0px 16px 0px #00000014;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: center;
                    color: #25272D;
                    transition: all 0.3s ease-in;
                    width: 162px;

                    &:hover {
                        background: #ee7930da;
                        color: #fff;
                    }
                }
            }

        }

        .personal_experience_session {
            padding: 12px 18px 70px 18px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media screen and (max-width:1024px) {
                padding-bottom: 0px;
            }

            .description_session {
                display: flex;
                flex-direction: column;
                gap: 12px;
                color: #25272D;

                @media screen and (max-width:1024px) {
                    border-bottom: 2px solid #F3F3F3;
                    padding-bottom: 20px;
                }

                .dec_title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                }

                .dec_body {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                }

            }

            .interest_session {
                display: flex;
                flex-direction: column;
                gap: 12px;
                color: #25272D;

                @media screen and (max-width:1024px) {
                    border-bottom: 2px solid #F3F3F3;
                    padding-bottom: 20px;
                }

                .interest_title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    justify-content: space-between;

                    @media screen and (min-width:601px) {
                        .resp_expand {
                            display: none;
                        }
                    }
                }

                .interest_chip_container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;

                    @media screen and (max-width:600px) {
                        &.responsive_action {
                            display: none;
                        }
                    }



                    .chip_interest {
                        width: fit-content;
                        padding: 4px 16px;
                        border-radius: 8px;
                        border: 1px solid #6C6D6F;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;

                        &.special {
                            color: #EE7830;
                            cursor: pointer;
                        }
                    }
                }

            }

            .qualification_session {
                display: flex;
                width: 100%;
                box-sizing: border-box;
                flex-direction: column;
                gap: 12px;
                color: #25272D;

                @media screen and (max-width:1024px) {
                    padding-bottom: 20px;
                    border-bottom: 2px solid #F3F3F3;
                }

                .equ_title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    justify-content: space-between;

                    @media screen and (min-width:601px) {
                        .resp_expand {
                            display: none;
                        }
                    }
                }

                .qual_card_wrap {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    box-sizing: border-box;

                    @media screen and (max-width:600px) {
                        &.responsive_action {
                            display: none;
                        }
                    }

                    .qual_card {
                        border: 1px solid #DCDCDC;
                        border-radius: 12px;
                        padding: 12px;
                        box-sizing: border-box;
                        width: 100%;

                        .card_header {
                            display: flex;
                            width: 100%;
                            gap: 12px;
                            justify-content: space-between;
                            align-items: center;

                            .title_card {
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 20px;
                                text-align: left;
                            }

                            .action_qual_btn {
                                display: flex;
                                align-items: center;
                                gap: 2px;
                                background: #ffffff;
                                border-radius: 8px;
                                border: none;
                                color: #EE7830;
                                transition: all 0.3s ease-in-out;

                                &:hover {
                                    cursor: pointer;
                                    text-decoration: underline;
                                }


                                .btn_text {
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 20px;
                                }

                            }
                        }

                        .card_duration {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            color: #6C6D6F;
                            padding: 3px 0px;
                        }

                        .card_desc {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                            color: #6C6D6F;
                        }
                    }
                }
            }

            .see_btn_wrap {
                display: flex;
                width: 100%;
                justify-content: center;

                .btn_see {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    background: #fff;
                    color: #EE7830;
                    cursor: pointer;
                    text-decoration: underline;
                    padding: 2px 18px;
                    border: none;
                    border-radius: 8px;

                    &:hover {
                        background: #ee79301b;
                    }
                }
            }
        }
    }

    .user_right_wrap {
        width: calc(100% - 414px - 28px);
        position: relative;

        @media screen and (max-width:1024px) {
            width: 100%;
            padding: 0px 20px;
            box-sizing: border-box;
        }

        .header_user_listings {
            padding-bottom: 20px;

            .header_title {
                font-size: 22px;
                font-weight: 600;
                line-height: 24px;
                text-align: center;
                color: #25272D;
                margin-bottom: 16px;

                @media screen and (max-width:600px) {
                    text-align: left;
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .sticky {
                width: calc(100% - 414px - 50px);
                padding-top: 16px;
                position: fixed;
                top: 56px;
                right: 0;
                background-color: white;
                z-index: 1000;
                box-sizing: border-box;
                box-shadow: 0px 9px 25px 0px #0000001A;

                // border-radius: 0px 0px 16px 16px;
                @media screen and (max-width:1024px) {
                    width: 100%;
                    top: 50px;
                }

                @media screen and (max-width:600px) {
                    top: 50px;
                }
            }

            .listing_tabs_wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.4s ease-in;


                @media screen and (max-width:600px) {
                    width: 100dvw;
                    margin-left: -20px;
                }

                .tabs_container {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 0px 38px 26px 38px;
                    cursor: pointer;
                    box-sizing: border-box;
                    border-bottom: 2px solid transparent;
                    transition: all 0.3s ease-in-out;
                    box-sizing: border-box;

                    @media screen and (max-width:600px) {
                        padding: 0px 0px 20px 0px;
                        border-bottom: 1px solid #F3F3F3;
                        width: 33.33%;
                        box-sizing: border-box;
                        justify-content: center;
                    }

                    .tab_title {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        color: #25272D;

                        @media screen and (max-width:600px) {
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }

                    .tabs_icon {
                        width: 24px;
                        height: 24px;

                        @media screen and (max-width:600px) {
                            width: 16px;
                            height: 16px;
                        }
                    }


                    &.active_tab {
                        border-bottom: 2px solid #EE7830;

                        .tab_title {
                            color: #EE7830;
                        }
                    }
                }

            }
        }

        .pagination_container {
            padding: 54px 24px 0px 24px;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }
}

.connected_pop {
    position: relative;

    .option_drop {
        position: absolute;
        top: 40px;
        box-shadow: 0px 4px 4px 0px #00000040, 0px 4px 4px 0px #00000040;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        min-width: 100%;
        width: max-content;
        z-index: 100;

        .options_data {
            padding: 12px 18px;
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #000000;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                background: #F3F3F3;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #F3F3F3;
            }

            &:first-child {
                border-radius: 8px 8px 0px 0px;
            }

            &:last-child {
                border-radius: 0px 0px 8px 8px;
            }
        }
    }

}