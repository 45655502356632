.info_message_wrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 4px;
    padding: 10px 12px;
    border-radius: 10px;
    background: #F3F3F3;
    // box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1215686275);

    .info_icon_message {
        padding: 2px;
        width: 16px;
        height: 16px;
    }

    .info_message_text {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #6C6D6F;
    }
}