.select_listing_container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 0px 20px;

    .skeleton_class {
            width: 169px;
            height: 228px;
            border-radius: 12px;
    
            @media screen and (max-width:768px) {
                width: 100%;
                height: 154px;
            }
        }
    
        .draft_btn_skeleton {
            width: 100%;
            height: 58px;
            border-radius: 12px;
        }
    
        .wrap_select_listing {
            display: flex;
            flex-direction: column;
            max-width: 546px;
    
            @media screen and (max-width:600px) {
                max-width: 100%;
                width: 100%;
            }
        }

    .heading_header {
        margin-top: 48px;
        margin-bottom: 48px;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: #25272D;

        @media screen and (max-width:600px) {
            margin-top: 20px;
            margin-bottom: 24px;
        }
    }

    .card_listing_type_wrap {
        display: flex;
        justify-content: stretch;
        gap: 20px;

        @media screen and (max-width:600px) {
            flex-direction: column;
            width: 100%;
            gap: 24px;
        }

        .card_inner {
            border: 1px solid #8F939A1F;
            border-radius: 12px;
            cursor: pointer;
            padding: 32px 16px 18px 16px;
            width: 169px;

            @media screen and (max-width:600px) {
                width: 100%;
                padding: 20px;
            }

            &:hover {
                background: #00000014;
            }

            .card-body {
                display: flex;
                flex-direction: column;
                height: 100%;
                gap: 8px;

                img {
                    width: 40px;
                    height: 40px;
                }

                .card_title {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    text-align: left;
                    color: #25272D;
                    min-height: 40px;
                }

                .card_desc {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: left;
                    color: #6C6D6F;
                }

                @media screen and (max-width:600px) {
                    img {
                        width: 32px;
                        height: 32px;
                    }

                    .card_title {
                        min-height: auto;
                    }
                }
            }

            .draft_container {
                margin-top: auto;
                display: flex;
                align-items: center;
                width: 100%;
                gap: 2px;

                .draft_saved_icon {
                    width: 18px;
                    height: 18px;

                }

                .draft_title {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #6C6D6F;
                }
            }

        }

    }


    .draft_btn_add {
        background-color: #EE7830;
        border: none;
        box-shadow: 0px 4px 32px 0px #00000014;
        border: 1px solid #E0E0E014;
        border-radius: 12px;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        padding: 16px;
        color: #fff;

        &:hover {
            background-color: #ee7930e9;
        }

        &:disabled {
            background: #DCDCDC;
            color: #25272D;
            pointer-events: all;
            cursor: not-allowed;
        }
    }
}