.list_interest_container {
    .modal-content {
        background: rgba(37, 39, 45, 0.4);
        display: grid;
        width: 100%;
        height: 100%;
        place-items: center;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        max-width: 398px;
        background: #fff;
        min-height: fit-content;
        border-radius: 12px;
        padding: 16px 20px 50px 20px;

        @media screen and (max-width:600px) {
            width: calc(100% - 36px);
            padding: 16px 16px 24px 16px;
        }
    }

    .all_interest_header {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .close_btn_all {
            // position: absolute;
            // right: 20px;
            // top: 16px;
            z-index: 2;
            width: 44px;
            height: 44px;
        }
    }

    .heading_title {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        text-align: center;
        color: #25272D;
        padding: 8px 0px 24px 0px;

        @media screen and (max-width:600px) {
            padding: 18px 0px 24px 0px;
        }

    }

    .body_all_list {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        gap: 10px;

        .list_chip {
            border: 1px solid #EE762F;
            padding: 7px 12px;
            border-radius: 16px;
            display: flex;
            gap: 6px;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            text-align: left;
            color: #25272D;
            height: fit-content;
            width: fit-content;


            &.button_chip {
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background: #EE762F;
                    color: #fff;
                }
            }
        }

        img {
            width: 16px;
            height: 16px;
        }
    }
}