/* autoprefixer: off */
* {
  padding: 0;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

.mt-26 {
  margin-top: 26px;
}

.contact_field {
  margin-bottom: 6px;
  .react-tel-input {
    border: 1px solid rgb(220, 220, 220);
    border-radius: 10px;
    padding: 3px 0px;
    .special-label{
      font-size: 10px;
      top: 8px;
      left: 7px;
    }
    .form-control {
      border: none !important;
      padding-top: 23px;
      // padding: 18px 14px 18px 58px;
      font-size: 16px;
      font-weight: 500;
    }
    .form-control:focus{
      border: none !important;
    }
    .flag-dropdown{
      top: 9px;
    }
  }
}

.signup_contact_field{
  .react-tel-input{
    
    .special-label{
      font-size: 10px;
      top: -8px;
      left: 7px;
    }
    .form-control{
      padding: 13.5px 14px 13.5px 58px;
      border: 1px solid rgb(220, 220, 220) !important;
      border-radius: 10px !important;
      font-size: 16px;
      font-weight: 500;
    }
    .form-control:focus{
      border: 1px solid rgb(220, 220, 220) !important;
    }
    .flag-dropdown{
      top: 0px;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

select {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #25272d;
}

select:focus {
  color: #25272d;
}

:focus-visible {
  outline: 0;
}

html,
body,
button {
}

input {
  display: block;
  background-color: transparent;
}

// input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.container {
  width: 100%;
}

.common_btn {
  width: 100%;
  border: none;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
  border-radius: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 13px 0;

  img {
    margin-right: 8px;
  }
}

.common_btn_grey {
  border: none;
  box-shadow: none;
  color: #6C6D6F;
  cursor: pointer;
  background: #EBEBEB;
  box-shadow: 0px 4px 32px 0px #00000014;
  padding: 16px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;

  img {
    margin-right: 8px;
  }
}

.common_btn_orange {
  border: none;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  background: #ee7830;
  border-radius: 12px;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 8px;
  }
}

.outline_btn_orange {
  background-color: #ffffff;
  box-shadow: none;
  border: 1px solid #ee7830;
  color: #ee7830;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  img {
    margin-right: 8px;
  }
}

.outline_btn_gray {
  color: #8f939a;
  border: 1px solid #8f939a;
  box-shadow: none;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  background-color: #fff;

  img {
    margin-right: 8px;
  }
}

.popup-inner {
  &:before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
  }

  .popup {
    position: fixed;
    min-height: 425px;
    max-width: 350px;
    width: calc(100% - 30px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 12px;
    text-align: center;
    z-index: 4;

    .imgb {
      padding: 0 15px;

      img {
        max-width: 100%;
        position: relative;
        top: -44px;
      }
    }

    .heading {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.005em;
      color: #171515;
      text-align: center;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.005em;
      padding: 0 16px;
      color: #333333;
    }

    .btn-outer {
      display: flex;
      width: 100%;
      margin-top: 32px;

      button {
        padding: 24px 20px;
        display: inline-block;
        border: 1px solid #f2f2f2;
        border-radius: 0px 0px 0px 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        width: 100%;
        line-height: 24px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: none;
        letter-spacing: -0.005em;
        color: #333333;
        background: transparent;

        img {
          display: none;
        }

        &:first-child {
          border-right: none;
          border-left: none;
        }

        &:last-child {
          color: #eb5757;
          border-right: none;
        }
      }
    }
  }

  &.image_popup {
    .popup {
      padding: 15px;
      min-height: auto;

      .button {
        padding-top: 15px;
        display: flex;
        gap: 10px;

        button {
          background: transparent;
          color: #333;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;

          &:first-child {
            border-right: 1px solid #f2f2f2;
          }

          &:last-child {
            color: #eb5757;
          }
        }
      }
    }
  }

  &.signout_popup {
    .popup {
      min-height: auto;
      padding-top: 30px;

      button {
        &:first-child {
          color: #eb5757;
        }

        &:last-child {
          color: #333333;
        }
      }
    }
  }
}

.input_box {
  position: relative;

  .count {
    position: absolute;
    right: 12px;
    top: 10px;
    font-size: 12px;
    z-index: 2;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #25272d;
  }

  .input_label {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    left: 12px;
    color: #25272d;
    top: 10px;
    z-index: 0;
  }

  input {
    width: 100%;
    padding: 26px 12px 8px;
    padding-right: 45px;
    // border: 1px solid #8f939a;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    background: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #25272d;

    &:disabled {
      background: #fff;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .field-endIcon-wrap{
    position: absolute;
    right: 12px;
    top: 0px;
    display: flex;
    gap: 10px;
    // min-height: 50px;
    height: 100%;
    align-items: center;
  }

  img {
    &.cross-icon {
      // position: absolute;
      top: 13px;

      right: 8px;
      z-index: 5;
      cursor: pointer;

      &.disabled {
        display: none;
      }
    }

    &.icon_adjust {
      // right: 42px;
    }

    &.icon_adjust_top {
      top: 14px;
    }
  }

  img.eye-show-hide {
    position: absolute;
    top: 20px;
    z-index: 2;

    right: 18px;
    cursor: pointer;
  }
}

.booking-desc{
  .input_box{
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    textarea{
      margin-top: 31px;
      border: none;
      padding-top: 0px;
    }
  }
}

.input-radio-design {
  .cross-icon {
    display: none;
  }
}

.text-danger {
  font-size: 14px;
  color: #ee7830;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 2px;
  text-align: left;
  display: block;

  &.abn-not-active {
    margin-top: -22px;
  }
}

.form_group {
  margin-bottom: 9px;
}

.progressbar {
  margin: 8px 0 36px;
  background: #f3f3f3;
  height: 4px;
  width: 100%;
  z-index: 10;
  position: relative;

  span {
    background: #ee772f;
    display: block;
    height: 4px;
    width: 100%;
    max-width: 39%;
  }
}

.empty-container {
  min-height: 100vh;
}

.coming_soon {
  background: #f6f6f6;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 55px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #25272d;

  img {
    position: absolute;
    top: 25px;
    left: 55px;
  }
}

@media screen and (max-width: "768px") {
  .coming_soon {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

textarea {
  // border: 1px solid #8f939a;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  width: 100%;
  padding: 26px 55px 12px 12px;
  resize: none !important;
  min-height: 122px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #25272d;
}

.dob_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .input_box {
    width: 100%;
    margin-right: 12px;

    &:last-child {
      margin-right: 0px;
    }
  }

  .day,
  .year {
    max-width: 120px;
  }

  select:focus {
    outline: none;
    border: none;
    border: 1px solid #8f939a;
  }

  select {
    width: 100%;
    // border: 1px solid #8f939a;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    min-height: 60px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 26px 12px 10px;
    font-style: normal;
    color: #25272d;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("../img/downarrow.svg");
    background-repeat: no-repeat;
    background-position: 93% center;
  }
}

.floating_input .input_label{
  font-size: 16px;
  display: flex;
  align-items: center;
  top: 18px;
  pointer-events: none;
}
.floating_input input {
  padding: 12px 43px 12px 12px !important;
}
// for condition in which pre content is come like country code etc
.floating_input.floating_pre_text{
// padding-left: 30px !important;
}
.floating_input.floating_pre_icon .input_label{
 padding-left: 30px;
}
.floating_input.focused .input_label{

  top: -7px !important;
  background-color: #fff;
  transition: all 0.1s ease-in-out;
  bottom: unset !important;
  font-size: 10px !important;
  padding: 0px 2px;
  z-index: 1;
}
.another-dob {
  flex-direction: column;
}

.password {
  position: relative;

  img {
    // position: absolute;
    top: 25px;
    right: 12px;
    cursor: pointer;
    z-index: 4;
  }
}

.password_box {
  position: relative;

  img {
    position: absolute;
    top: 18px;
    right: 20px;
    z-index: 4;

    cursor: pointer;
  }
}

.extramsg {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 0 !important;
  padding-top: 4px !important;
}

.note {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #25272d;
  padding-bottom: 10px;
  padding-top: 20px;
  text-align: center;

  a {
    color: #25272d;
    font-weight: 500;
  }
}

select option:first-child {
  color: red;
}

.button_option {
  display: grid;
  gap: 20px;

  .cancel {
    background: transparent;
    color: #25272d;
    display: inline-block;
    width: fit-content;
    margin: 0 auto;
  }
}

.toast_error {
  background: #eb5757;
}

.Toastify__toast {
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  min-width: 325px !important;
}

.toast_success {
  background: #40b67d;
}

.gender_title {
  text-align: center;
  margin: 6px 0 16px;
}

.gender_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 482px;
  margin: auto;
  position: relative;
  gap: 20px;

  .gender_option {
    position: relative;
    display: flex;
    align-items: center;
    background: #f6f6f7;
    border-radius: 12px;
    padding: 12px 22px 12px 12px;
    min-width: fit-content;
    width: fit-content;
    margin-right: 16px;
    cursor: pointer;
    margin-bottom: 30px;

    .form_group {
      margin-bottom: 0;
    }

    .cross-icon {
      display: none;
    }

    &:last-child {
      margin-right: 0px;
    }

    .input_box {
      position: initial;
    }

    >img {
      width: 32px;
      height: 32px;
    }

    .input_box {
      textarea {
        // border: 1px solid #8f939a;
        border: 1px solid #dcdcdc;
        border-radius: 10px;
      }

      input {
        position: absolute;
        opacity: 0;

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      input[type="radio"] {
        cursor: pointer;
      }

      .input_label {
        position: static;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: block;
        padding: 0 10px;
        white-space: nowrap;
      }
    }

    .check-icon {
      display: none;
      opacity: 0;
      width: 24px;
      height: 24px;
      background: url(../img/checked.svg);
      background-size: contain;
      // position: absolute;
      right: 12px;
      z-index: -1;
    }
  }

  .text-danger {
    width: max-content;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }

  .gender_option.active {
    padding: 12px;
    background: linear-gradient(167.07deg,
        rgba(238, 120, 48, 0.12) -15.49%,
        rgba(238, 118, 47, 0.12) 149.41%);
    border: 1px solid #ee7830;

    .check-icon {
      display: flex;
      opacity: 1;
      z-index: 2;
    }
  }
}

@media screen and (max-width: "768px") {
  .gender_wrapper {
    gap: 10px;
    overflow: auto;

    .gender_option {
      >img {
        width: 24px;
        height: 24px;
      }

      .input_box {}
    }
  }
}

@media screen and (max-width: "457px") {
  .gender_wrapper {
    justify-content: start;
  }
}

.social_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;

  .social_text {
    color: var(--SteelBlue, #25272d);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
  }
}

.social_btn {
  border: 0px;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 0 !important;
  height: auto !important;
  background-color: transparent !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #25272d !important;
  margin-left: 4px !important;
  min-height: 56px !important;
  padding-left: 10px !important;
  width: 100% !important;

  svg {
    display: none;
  }

  &.apple {
    background: url(../img/apple.svg) !important;
    background-size: 32px !important;
    background-repeat: no-repeat !important;
    background-position: left !important;
  }

  &.fb {
    background: url(../img/fb.svg) !important;
    background-size: 32px !important;
    background-repeat: no-repeat !important;
    background-position: left !important;
  }

  &.google {
    background: url(../img/google.svg) !important;
    background-size: 32px !important;
    background-repeat: no-repeat !important;
    background-position: left !important;
  }
}

.Toastify__close-button--light {
  color: #fff;
  opacity: 1;
  margin-top: 15px;
  margin-right: 10px;
}

.mb_24 {
  margin-bottom: 24px;
}

.unlink_modal {
  .desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #8f939a;

    b {
      color: #25272d;
      font-weight: 400;
    }
  }

  .pass_box {
    position: relative;

    .close_eye {
      position: absolute;
      top: 50%;
      right: 50px;
      z-index: 4;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

.top-bar {
  padding: 23px 55px;
  background: #ffffff;
  width: 100%;

  .profile-managment-top {
    padding-bottom: 0;
  }
}

.top-bar-new {
  padding: 23px 0px;
  background: #25272d;
  width: 100%;
}

@media screen and (max-width: "768px") {
  .top-bar {
    padding: 1rem 1rem;
  }
}

.profile-managment-top {
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    display: flex;
  }

  .right-menu {
    display: flex;
    gap: 66px;
    align-items: center;

    .menus {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        height: 100%;
        border-left: 1px solid #d9d9d9;
        right: -33px;
      }

      ul {
        gap: 48px;

        @media screen and (max-width: "1200px") {
          gap: 20px;
        }

        li {
          a {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #25272d;
            text-decoration: none;
            min-width: 80px;

            &.active {
              color: #ee772f;
            }
          }
        }
      }
    }

    .icon-menu {
      ul {
        gap: 16px;
        align-items: center;

        li {
          img {
            cursor: pointer;
          }

          &.menu-des {
            .inner {
              display: flex;
              border: 1px solid #d9d9d9;
              border-radius: 100px;
              padding: 4px;
              padding-left: 14px;
              align-items: center;
              gap: 8px;

              .profile {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                border: 2.21538px solid #ffffff;
                filter: drop-shadow(0px 0px 6.64615px rgba(0, 0, 0, 0.12));
                object-fit: cover;
              }
            }

            .inner:hover {
              display: flex;
              border: 1px solid #ee762f;
              background: linear-gradient(167.07deg,
                  rgba(238, 120, 48, 0.1) -15.49%,
                  rgba(238, 118, 47, 0.1) 149.41%);

              border-radius: 100px;
              padding: 4px;
              padding-left: 14px;
              align-items: center;
              gap: 8px;

              .profile {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                border: 2.21538px solid #ffffff;
                filter: drop-shadow(0px 0px 6.64615px rgba(0, 0, 0, 0.12));
                object-fit: cover;
              }
            }

            @media screen and (max-width: "768px") {
              .inner {
                .profile {
                  width: 32px;
                  height: 32px;
                }

                .menu-icon {
                  width: 22px;
                  height: 22px;
                }
              }
            }
          }
        }

        li.header-notify-icon {
          position: relative;

          .notify-badge {
            position: absolute;
            background: #ee762f;
            border-radius: 100%;
            display: flex;
            top: -7px;
            right: -7px;

            .nine-plus {
              font-size: 10px;
              color: #ffffff;
              // padding: 2px 4px;
              border-radius: 100%;
              width: 16px;
              height: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .no-plus {
              font-size: 10px;
              color: #ffffff;
              // padding: 2px 4px;
              border-radius: 100%;
              width: 15px;
              height: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .right-menu-new {
    .menus {
      &:before {
        content: unset;
        position: none;
        height: unset;
        border-left: unset;
        right: unset;
      }

      ul {
        li {
          .inactive {
            color: #ffffff !important;
          }
        }
      }
    }
  }
}

.fixed-sidebar {
  position: fixed;
  background: #25272dcc;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;

  .sidebar-box {
    background: #ffffff;
    max-width: 672px;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
    margin-left: auto;
    padding: 36px 20px;

    .close-btn {
      cursor: pointer;
    }

    .inner-contet {
      max-width: 452px;
      margin-right: auto;
      width: 100%;
      margin-top: 42px;
    }

    .search-list {
      margin-top: 24px;
      padding-left: 32px;
      max-height: calc(100vh - 230px);
      overflow: auto;
    }

    @media screen and (max-width: "768px") {
      .search-list {
        padding-left: 0px;
      }
    }

    .no-data {
      text-align: center;
      margin-top: 66px;

      img {
        max-width: 147px;
        width: 100%;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #8f939a;
        max-width: 321px;
        margin: 0 auto;
        margin-top: 57px;
      }
    }
  }
}

.fixed-sidebar-menu {
  position: fixed;
  background: #25272dcc;
  width: 100%;
  height: 100%;
  top: 0;
  right: -9999px;
  transition: 0.4s;
  z-index: 99;

  .sidebar_menu {
    background: #ffffff;
    width: 90%;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
    margin-left: auto;
    position: fixed;
    right: -9999px;
    transition: 0.4s;

    .profile_line {
      height: 2px;
      border-radius: 100px;
      background: var(--FitnessNonSelected, #f3f3f3);
    }

    .profile_container {
      display: flex;
      gap: 10px;

      .profile {
        width: 65px;
        height: 65px;
        border-radius: 65px;
        border: 4px solid var(--FitnessWhite, #fff);
        background: lightgray 50% / cover no-repeat;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
      }

      .profile-video {
        object-fit: cover;
      }

      .profile_info {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;

        .profile_content {
          span {
            color: var(--SecondarySteel, #8f939a);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }

          h4 {
            color: var(--SteelBlue, #25272d);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
          }
        }

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .all_menus {
      padding: 0px;

      .menu_item {
        display: flex;
        margin-bottom: 24px;
        list-style: none;
        justify-content: space-between;
        align-items: start;

        p {
          color: var(--SteelBlue, #25272d);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          margin-bottom: 0px;
        }
      }
    }
  }

  .sidebar_menu.open {
    right: 0;
  }
}

.fixed-sidebar-menu.open {
  right: 0;
}

.form-set {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;

  .form-group {
    position: relative;
    width: 100%;

    input {
      background: #f2f2f2;
      border-radius: 12px;
      width: 100%;
      height: 60px;
      padding: 20px 44px;
      border: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #25272d;

      &::-webkit-input-placeholder {
        /* Edge */
        color: #8f939a;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #8f939a;
      }

      &::placeholder {
        color: #8f939a;
      }

      &+label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
        display: grid;

        img {
          &.black-search {
            display: none;
          }
        }

        &+img.corss-icon {
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &:focus,
      &:focus-visible,
      &:focus-within {
        &::-webkit-input-placeholder {
          /* Edge */
          color: #25272d;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #25272d;
        }

        &::placeholder {
          color: #25272d;
        }

        &+label {
          img {
            &.gray-search {
              display: none;
            }

            &.black-search {
              display: inherit;
            }
          }
        }
      }
    }
  }

  button {
    background: linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
    border-radius: 12px;
    padding: 16px;
    border: 0;
    display: grid;
  }

  @media screen and (max-width: "768px") {
    .form-group {
      input {
        height: 48px;
      }
    }

    button {
      padding: 10px;
    }
  }
}

.search-list {
  display: grid;
  gap: 17px;

  .on-list {
    display: flex;
    justify-content: space-between;
    padding: 15px 12px;
    padding-left: 0;
    align-items: center;
    position: relative;
    cursor: pointer;

    &:last-child {
      &::after {
        display: none;
      }
    }

    a {
      display: grid;
    }

    .top-part {
      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: rgba(37, 39, 45, 0.7);
        word-break: break-all;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #8f939a;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }

  @media screen and (max-width: "768px") {
    .on-list {
      padding-right: 0;
    }
  }
}

.user-profile {
  background: #f9f9f9;
  padding-top: 27px;

  .event_name {
    color: var(--SteelBlue, #25272d);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &.create_listing {
    min-height: calc(100dvh - 90px);
    padding-top: 0px;
    background: #ffffff;

    .disabled {
      background: linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
    }
  }

  @media screen and (max-width: "768px") {
    &.create_listing {
      min-height: calc(100vh - 62px);
    }
  }

  .user-white-part {
    max-width: 734px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 24px;
    padding: 74px 94px;

    .button-design {
      margin-top: 28px;
      margin-bottom: 40px;
    }

    hr {
      border-top: 1px solid #8f939a29;
      margin-bottom: 32px;
    }

    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #25272d;
    }

    .my-interset {
      margin-bottom: 32px;
    }

    .bio-conten {
      margin-bottom: 32px;
    }
  }

  .user-white-part-width {
    padding: 48px 55px;
    max-width: 1500px;
    min-height: calc(100vh - 94px);
    margin: 0 auto;

    position: relative;

    .user-white-part-large {
      background: #ffffff;
      padding: 35px 94px;

      &.listing_types {
        min-height: calc(100vh - 242px);
      }

      .top-parts {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .top-left-part {
          display: flex;
          gap: 16px;
          align-items: start;

          .profile-img {
            width: 170px;
            height: 170px;
            border-radius: 50%;
            border: 4px solid #ffffff;
            /* filter: drop-shadow(0px 8.94737px 44.7368px rgba(0, 0, 0, 0.12)); */
            box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.12);
            object-fit: cover;
            overflow: hidden;
            position: relative;

            video {
              cursor: pointer;
            }

            .videoicon {
              position: absolute;
              z-index: 2;
              left: 50%;
              transform: translate(-50%, -50%);
              top: 50%;
              cursor: pointer;
            }

            video {
              width: 100%;
              height: 100%;
            }
          }

          .contste {
            h3 {
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 40px;
              color: #25272d;
              display: flex;
              align-items: center;
              gap: 8px;
              margin-bottom: 8px;

              span {
                white-space: nowrap;
                width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              img {
                width: 24px;
                height: 24px;
              }
            }

            ul {
              display: flex;
              align-items: center;
              gap: 30px;
              list-style: none;

              &.user-data {
                margin-bottom: 36px;

                li {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                }
              }

              &.user-ratings {
                li {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  color: #8f939a;

                  span {
                    color: #bdbdbd;
                    font-weight: 400;
                  }
                }
              }
            }
          }
        }

        .top-right-part {
          ul.user-buttons {
            list-style: none;
            padding-left: 0;
            display: flex;
            gap: 8px;

            li {
              button {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #25272d;
                padding: 8px 12px;
                background: #f2f3f3;
                border-radius: 8px;
                border: 0;
                cursor: pointer;
                height: 40px;

                img {
                  height: 25px;
                }

                &.accept {
                  background: linear-gradient(167.07deg,
                      #ee7830 -15.49%,
                      #ee762f 149.41%);
                  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
                  border-radius: 8px;
                  color: #ffffff;
                  padding: 8px 24px;
                }

                &.icon {
                  padding: 4px 12px;
                }
              }
            }
          }
        }
      }

      hr {
        border-top: 1px solid #edeeef;
        margin-top: 25px;
        margin-bottom: 32px;
      }

      .mid-part {
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #25272d;
          margin-bottom: 24px;
        }

        h6 {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #25272d;
          margin-bottom: 8px;
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          gap: 8px;
          margin-bottom: 63px;

          li {
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 8px;
            padding: 4px 16px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #25272d;
          }
        }
      }

      .bottom-part {
        .top-tabs {
          border-top: 1px solid #edeeef;

          ul {
            display: flex;
            list-style: none;
            justify-content: center;
            align-items: center;

            li {
              display: flex;
              align-items: center;
              gap: 4px;
              cursor: pointer;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 20px;
              color: #25272d;
              padding: 0 36px;
              padding-top: 14px;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background: linear-gradient(167.07deg,
                    transparent -15.49%,
                    transparent 149.41%);
                border-radius: 0px 0px 12px 12px;
              }

              &.active {
                &::after {
                  background: linear-gradient(167.07deg,
                      #ee7830 -15.49%,
                      #ee762f 149.41%);
                }
              }
            }
          }
        }

        .tab-content {
          margin-top: 42px;

          .listings {
            display: grid;
            gap: 20px;
            flex-wrap: wrap;
            grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
          }
        }
      }

      .listing-type {
        max-width: 546px;
        margin: 0 auto;
        padding-top: 90px;

        .back_arrow {
          position: absolute;
          margin-left: -250px;
          cursor: pointer;
          margin-top: -15px;

          @media screen and (max-width: "1200px") {
            margin-left: -50px;
          }
        }

        h6 {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #25272d;
          text-align: center;
          margin-bottom: 12px;
        }

        ul {
          list-style: none;
          padding-left: 0;
          margin: 0;
          display: flex;
          gap: 20px;
          justify-content: center;

          li {
            max-width: 169px;
            width: 100%;

            .card {
              background: #ffffff;
              border: 1px solid rgba(143, 147, 154, 0.12);
              border-radius: 12px;
              width: 100%;
              cursor: pointer;
              height: 100%;

              .card-body {
                padding: 40px 16px 32px;

                img {
                  width: 40px;
                  height: 40px;
                  object-fit: contain;
                  margin-bottom: 16px;
                }

                h4 {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  color: #25272d;
                  margin-bottom: 8px;
                  min-height: 40px;
                }

                p {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #8f939a;
                  margin-bottom: 0;
                  min-height: 54px;
                }

                .draft_available {
                  display: flex;
                  align-items: center;
                  min-height: auto;
                  padding-top: 12px;

                  img {
                    width: 18px;
                    margin: 0;
                    height: auto;
                  }
                }
              }
            }
          }
        }

        .or-desing {
          position: relative;
          margin-top: 18px;
          margin-bottom: 18px;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #edeeef;
          }

          span {
            text-align: center;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            position: relative;
            z-index: 9;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #8f939a;
            background-color: #fff;
            text-transform: uppercase;
          }
        }

        button {
          background: #f3f3f3;
          border: 1px solid rgba(224, 224, 224, 0.08);
          box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
          border-radius: 12px;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #25272d;
          padding: 16px;
          width: 100%;
          cursor: pointer;
        }

        &+hr {
          border-top: 1px solid #edeeef;
          margin-top: 124px;
          margin-bottom: 40px;
        }
      }

      .lsiting-detials {
        max-width: 546px;
        margin: 0 auto;
        position: relative;
        margin-top: 20px;

        .image_box {
          padding-bottom: 30px;

          .listingImage {
            margin-bottom: 0;
          }
        }

        .listing_desc {
          .input_label {
            background: white;
            top: 1px;
            padding-top: 8px;
            width: calc(100% - 24px);
            padding-bottom: 5px;
          }
        }

        .back_arrow {
          position: absolute;
          margin-left: -250px;
          cursor: pointer;

          @media screen and (max-width: "1200px") {
            margin-left: -50px;
          }
        }

        .main-heaidng {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;

          h6 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #25272d;
            margin-bottom: 0;
          }

          img {
            width: 24px;
            height: 24px;
            cursor: pointer;
            margin-right: 16px;
            @media screen and (max-width:768px) {
                margin-right: 0px;
              }
          }
        }

        .form-hr {
          border: 0;
          border-top: 1px solid #edeeef;
          margin-top: 31px;
          margin-bottom: 27px;

          &.last-hr {
            margin-top: 50px;
          }
        }

        .main-had {
          display: flex;
          justify-content: space-between;
          gap: 16px;
          margin-bottom: 12px;

          h3 {
            &.headis {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #25272d;
              margin-bottom: 0;
            }
          }

          .add-upto {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            .headis-orange {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;

              display: flex;
              align-items: center;

              color: #ee762f;
              margin-bottom: 0px;
            }

            .headis-grey {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;

              display: flex;
              align-items: center;

              color: #8f939a;
              margin-bottom: 0px;
              cursor: pointer;
            }

            img {
              width: 20px;
              height: 20px;
            }
          }

          .info_icon {
            margin-right: 16px;
            cursor: pointer;

            @media screen and (max-width:768px) {
            margin-right: 0px;
            }
          }
        }

        // .input-special {
        //   input {
        //     padding-top: 8px;
        //   }

        //   img {
        //     top: 9px;
        //   }
        // }

        .input-special {
          .input_box {
            textarea {
              padding: 8px 55px 8px 8px;
              height: 44px;
              min-height: 44px;
            }

            .count {
              top: 48px;
            }
          }

          img {
            top: 9px;
          }
        }

        .instructions-wrapper {
          // border: 5px solid green;

          .instruction {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            border: 1px solid #8f939a;

            border-radius: 8px;
            padding: 12px 14px 12px 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            margin-bottom: 16px;

            img {
              cursor: pointer;
            }
          }
        }

        .two-row {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
        }

        .pay_info_wrapper {
          .note_info {
            color: var(--SteelBlue, #25272d);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            span {
              font-weight: 500;
            }
          }

          .platform_fee,
          .merchant_fee {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }

      @media screen and (max-width: "1200px") {
        padding: 0;

        .listing-type {
          padding-top: 12px;
        }
      }
    }
  }

  @media screen and (max-width: "768px") {
    .user-white-part-width {
      padding: 0;
      position: static;
      min-height: calc(100vh - 62px);

      .user-white-part-large {
        padding: 0;

        .listing-type {
          padding: 1rem;

          .back_arrow {
            position: absolute;
            margin-left: 0px;
            cursor: pointer;
            margin-top: -15px;
          }

          h6 {
            margin-bottom: 16px;
          }

          ul {
            flex-direction: column;

            li {
              max-width: 100%;
              width: 100%;

              .card {
                padding: 1rem;

                .card-body {
                  padding: 0;

                  img {
                    width: 32px;
                    height: 32px;
                  }

                  h4 {
                    min-height: 0px;
                  }

                  p {
                    min-height: 0;
                  }
                }
              }
            }
          }

          button {
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
        }

        .lsiting-detials {
          padding: 1rem;

          .image_box {
            .listing_image {
              height: 175px;
            }
          }

          .back_arrow {
            margin-left: 0px;
          }

          .all-radio-button_with-check {
            display: block;

            .form_radio_button_with_check {
              margin-bottom: 1rem;
            }
          }

          .two-row {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
          }

          .pay_info_wrapper {
            .note_info {
              color: var(--SecondarySteel, #8f939a);
              font-size: 10px;
              line-height: 18px;

              span {
                color: var(--SteelBlue, #25272d);
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
              }
            }

            .platform_fee,
            .merchant_fee {
              color: var(--SteelBlue, #25272d);
              font-size: 10px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.private-checked {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #25272d;
  }
}

.checktoggle {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .form_group {
    margin: 0;
  }

  .cross-icon {
    display: none;
  }

  input {
    position: absolute;
    width: 36px;
    height: 20px;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
  }

  .checked_box {
    background-color: #d9d9d9;
    width: 36px;
    height: 20px;
    position: relative;
    display: block;
    border-radius: 80px;

    &::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      left: 5px;
      top: 4px;
      background: #f3f3f3;
      border-radius: 100px;
      transition: all 0.5s;
    }
  }
}

.custom_check {
  position: relative;

  &.checked {
    .checked_box {
      background: linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);

      &::after {
        left: 20px;
      }
    }
  }
}

.cards {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 12px;

  img.card-profile {
    border-radius: 12px 12px 0px 0px;
    background: #d9d9d9;
    max-width: 100%;
    width: 100%;
  }

  .card-body {
    padding: 0 12px;
    padding-bottom: 22px;

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #25272d;
      margin-bottom: 20px;
    }

    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;

      &.km-data {
        margin-bottom: 16px;
        margin-top: -23px;

        li {
          &:first-child {
            background: rgba(0, 0, 0, 0.32);
            backdrop-filter: blur(16px);
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            padding: 4px 16px;
          }

          &:nth-child(2) {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            padding: 4px 12px;
            background: linear-gradient(167.07deg,
                #ee7830 -15.49%,
                #ee762f 149.41%);
            border-radius: 8px;
          }
        }
      }

      &.calendar-part {
        margin-bottom: 12px;

        li {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #8f939a;
          display: flex;
          align-items: center;
          gap: 4px;

          b {
            color: #25272d;
            font-weight: 500;
          }
        }
      }
    }

    p.clock-set {
      display: flex;
      align-items: center;
      gap: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #8f939a;
    }
  }

  .listing-card-body {
    padding: 0 12px;
    padding-bottom: 22px;

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #25272d;
      margin-bottom: 20px;
    }

    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;

      &.km-data {
        margin-bottom: 16px;
        margin-top: -23px;

        li {
          &:first-child {
            background: rgba(0, 0, 0, 0.32);
            backdrop-filter: blur(16px);
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            padding: 4px 16px;
          }

          &:nth-child(2) {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            padding: 4px 12px;
            background: linear-gradient(167.07deg,
                #ee7830 -15.49%,
                #ee762f 149.41%);
            border-radius: 8px;
          }
        }
      }

      &.calendar-part {
        margin-bottom: 12px;

        li {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #8f939a;
          display: flex;
          align-items: center;
          gap: 4px;

          b {
            color: #25272d;
            font-weight: 500;
          }
        }
      }
    }

    p.clock-set {
      display: flex;
      align-items: center;
      gap: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #8f939a;
    }
  }
}

.top-media-part {
  display: flex;
  gap: 20px;
  align-items: center;

  .profile-img {
    border: 6px solid #ffffff;
    filter: drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.12));
    border-radius: 50%;
    width: 114px;
    height: 114px;
    object-fit: cover;
  }

  .contente {
    width: 100%;

    .heain {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #25272d;
      }

      img.side-icon {
        border-radius: 50%;
        padding: 5px;
        cursor: pointer;
      }
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: 8px;

      li {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #8f939a;
      }
    }
  }
}

.button-design ul {
  list-style: none;
  display: flex;
  gap: 12px;

  li {
    &:first-child {
      width: 100%;
    }

    button {
      padding: 12px;
      border: 0;
      display: grid;
      background: #f2f3f3;
      border-radius: 8px;

      &.orange {
        background: linear-gradient(167.07deg,
            #ee7830 -15.49%,
            #ee762f 149.41%);
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
        width: 100%;
        padding: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        gap: 8px;
      }
    }
  }
}

.my-interset {
  .boxing {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #25272d;
      padding: 4px 16px;
      border: 1px solid #f3f3f3;
      border-radius: 8px;
    }
  }
}

.bio-conten {
  h6 {
    margin-bottom: 12px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #25272d;
  }
}

.all-collapse {
  .collapse-grid {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    h6 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 19px;

      &.active {
        color: #ee7830;
      }
    }

    .collapse-grid-img {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      .all-img {
        max-width: 168px;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }
}

.loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.fullLoader {
  display: flex;
  justify-content: center;

  img {
    width: 100px !important;
  }
}

.loader_wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.password_management_icon {
  right: 50px !important;
}

.form_readio_button {
  margin-bottom: 10px;

  input {
    display: none;
  }

  &+label {
    position: relative;
    padding-left: 38px;
    cursor: pointer;

    &::after {
      content: "";
      width: 24px;
      height: 24px;

      position: absolute;
      top: 0;
      left: 0;

      background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="2.83333" y="2.83333" width="18.3333" height="18.3333" rx="9.16667" stroke="%23E0E0E0" stroke-width="1.66667"/></svg>');
    }
  }

  &:checked {
    &+label {
      &::after {
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="2.83333" y="2.83333" width="18.3333" height="18.3333" rx="9.16667" stroke="url(%23paint0_linear_5122_47060)" stroke-width="1.66667"/><rect x="6.16699" y="6.16699" width="11.6667" height="11.6667" rx="5.83333" fill="url(%23paint1_linear_5122_47060)"/><defs><linearGradient id="paint0_linear_5122_47060" x1="-1.64103" y1="-0.973933" x2="7.20436" y2="37.5493" gradientUnits="userSpaceOnUse"><stop stop-color="%23EE7830"/><stop offset="1" stop-color="%23EE762F"/></linearGradient><linearGradient id="paint1_linear_5122_47060" x1="4.04306" y1="4.4322" x2="9.20287" y2="26.9041" gradientUnits="userSpaceOnUse"><stop stop-color="%23EE7830"/><stop offset="1" stop-color="%23EE762F"/></linearGradient></defs></svg>');
      }
    }
  }
}

.all-radio-button_with-check {
  display: flex;
  gap: 8px;
  position: relative;
  margin-bottom: 24px;

  &.difficulty_level {
    .form_radio_button_with_check {
      position: initial;
    }

    .text-danger {
      position: absolute;
      left: 0;
      bottom: -30px;
    }
  }
}

.all-radio-button {
  display: grid;
  gap: 8px;

  label {
    margin-bottom: 0 !important;
    color: #8f939a;

    &.active {
      color: #25272d;
    }
  }
}

.form_radio_button_with_check {
  width: 100%;
  position: relative;

  .form_group {
    margin-bottom: 0;
  }

  .input_box {
    position: initial;
  }

  label {
    background: #f6f6f7;
    border: 2px solid #f6f6f7;
    border-radius: 12px;
    padding: 12px;
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    cursor: pointer;

    img {
      &.left-img {
        background: #ffffff;
        padding: 8px 8.5px;
        border-radius: 50%;

        &.img-orange {
          display: none;
        }
      }

      &.checkmark-circle {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        display: none;
      }
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #25272d;
      margin-bottom: 0 !important;
    }
  }

  label.checked {
    background: linear-gradient(167.07deg,
        rgba(238, 120, 48, 0.12) -15.49%,
        rgba(238, 118, 47, 0.12) 149.41%);
    border-radius: 12px;
    border: 2px solid #ee7830;

    p {
      background: linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    img {
      &.left-img {
        &.gay-img {
          display: none;
        }

        &.img-orange {
          display: inherit;
        }
      }

      &.checkmark-circle {
        display: inherit;
      }
    }
  }

  input {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }

  .cross-icon {
    display: none;
  }

  &.center-text {
    label {
      text-align: center;
      justify-content: center;
    }
  }
}

.wth-dorpwodn {
  position: relative;

  .data-setup {
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0px 4px 8px 0px #0000000f;
    box-shadow: 0px 0px 4px 0px #0000000a;
    position: absolute;
    width: 100%;
    background: #fff;
    z-index: 9;
    scrollbar-width: "thin";

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      gap: 12px;
      flex-wrap: wrap;

      li {
        display: grid;
        gap: 0;
        padding: 0;
        margin: 0;
        list-style: none;
        max-width: 162px;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        overflow: hidden;

        img {
          border-radius: 8px 8px 0px 0px;
          width: 164px;
          object-fit: cover;
          height: 180px;
        }

        .tick {
          width: 24px;
          height: 24px;
        }

        p {
          border-top: 1px solid #e0e0e0;
          border-radius: 0px 0px 8px 8px;
          padding: 6px 10px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #25272d;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0px;
        }

        &.active {
          border: 1px solid #ee762f;

          p {
            background: #fff4ee;
            color: #ee762f;
            border-top: 1px solid #ee762f;
          }
        }
      }

      @media screen and (max-width: "767px") {
        li {
          max-width: 144px;
        }
      }
    }

    .no_data {
      color: #8f939a;
      text-align: center;

      img {
        width: 32px;
      }

      h3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  @media screen and (max-width: "768px") {
    .selected_title {
      padding-left: 0;
    }

    .data-setup {
      border: 0;
      box-shadow: 0 0 0 0;
      box-shadow: 0 0 0 0;
      padding: 0;
      border-radius: 0px;

      ul {
        flex-wrap: nowrap;

        li {
          overflow: visible;
          max-width: 162px;

          img {
            width: 159px;
          }
        }
      }
    }
  }
}

.selected_title {
  display: flex;
  list-style: none;
  gap: 10px;
  flex-wrap: wrap;

  li,
  .more {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      cursor: pointer;
    }
  }
}

.gender_preference {
  display: flex;

  input {
    width: 20px;
    accent-color: #ee7830;
  }

  .cross-icon {
    display: none;
  }
}

.google_search_bar_wrapper {
  display:flex ;
  align-items: center ;
  padding-bottom:6px ;
  position: absolute ;
  right: 12px ;
  height:100% ;
}


.google_search_bar {
  .current_location_icon {
    cursor: pointer;
  }

  .maps-container {
    width: 75%;
    height: calc(100vh - 180px);
    border-radius: 12px;
  }

  .map-input {
    max-width: 460px;
    width: 100%;
  }
}

.sidebar-map {
  display: flex;
  gap: 24px;
}

.no_fav_list {
  padding: 90px 0;

  h3 {
    font-size: 16px;
    color: #8f939a;
    font-weight: 500;
    padding-top: 32px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #8f939a;
  }
}

.form_radio_button {
  display: flex;
  gap: 8px;
  align-items: center;

  label {
    margin-bottom: 24px;
  }

  .cross-icon {
    display: none;
  }

  input[type="radio"] {
    width: 24px;
    height: 24px;
    opacity: 0;
  }

  .check_img {
    position: absolute;
  }
}

.recurring_type {
  display: flex;
  justify-content: space-between;
  list-style: none;

  gap: 20px;

  padding: 0px 20px 30px 24px;

  li {
    background: #f6f6f7;
    flex: 1;
    border-radius: 12px;
    padding: 12px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 500;
    color: #25272d;
    width: 32px;
    cursor: pointer;

    &.active {
      background-color: #fdefe6;
      border: 1px solid #ed772e;
      background-image: url(../img/checkmark-circle.svg);
      background-repeat: no-repeat;
      background-position: 95% center;
    }
  }
}

.form_row {
  display: flex;
  gap: 20px;
  padding: 0 20px 0;

  .repeat_every {
    max-width: 150px;

    .cross-icon {
      display: none;
    }
  }

  .select_frequency {
    flex: 1;
    position: relative;
    background-image: url(../img/downarrow.svg);
    background-repeat: no-repeat;
    background-position: 95% 25px;

    .frequency_option {
      background: #fff;
      list-style: none;
      padding: 16px;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px #0000000f;
      box-shadow: 0px 0px 4px 0px #0000000a;
      margin-top: 8px;
      position: absolute;
      width: 100%;
      border-radius: 12px;
      border: 1px solid #f3f3f3;

      li {
        text-transform: capitalize;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        padding: 8px 0 8px 12px;
        cursor: pointer;
        color: #8f939a;
        text-align: center;

        // border-bottom: 1px solid #f3f3f3;
        &:hover {
          background-color: #f6f6f7;
        }

        &.active {
          background: url(../img/checked.svg);
          background-repeat: no-repeat;
          background-position: 95% center;
          cursor: not-allowed;
        }

        &:last-child {
          border: none;
        }
      }

      &.selection {
        li {
          color: #25272d;
          text-align: left;
          font-weight: 600;
        }
      }
    }

    &.month_frequency {
      max-width: 375px;

      li {
        border-radius: 4px;

        &.active {
          background-color: #ee772f;
          color: #fff;
          background-image: url(../img/whitecheck.svg);
        }
      }
    }
  }
}

// .google_search_bar {
//   .cross-icon {
//     display: none;
//   }
// }

.listing_image {
  height: 255px;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.schedule_date_input {
  width: 100%;
  padding: 26px 12px 8px;
  padding-right: 45px;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  background: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #25272d;
  min-height: 60px;
  background-image: url(../img/rightarrow.svg);
  background-repeat: no-repeat;
  background-position: 98% center;

  small {
    color: #8f939a;
    font-size: 14px;
    line-height: 24px;
  }
}

//new date picker

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  border: none;
}

.react-datepicker-popper {
  width: auto !important;
  z-index: 4 !important;
}

.react-datepicker--time-only {
  border: 1px solid #8f939a14;
  padding: 0 20px;
}

.react-datepicker__triangle {
  display: none;
  left: 150px !important;
  transform: none !important;
}

.react-datepicker__navigation {
  width: 32px !important;
  text-align: inherit !important;
}

.react-datepicker__current-month,
.rmdp-header-values {
  color: #25272d;
  font-weight: 500;
  font-size: 16px;
  width: 70%;
  margin: 0 auto;
  border: 1px solid #f3f3f3;
  padding: 10px;
  border-radius: 10px;
  margin: 24px auto;
}

.react-datepicker__day-name,
.rmdp-week-day {
  color: #9c9d9f !important;
  font-size: 14px;
  font-weight: 500;

  width: 45px !important;

  line-height: 36px !important;
}

.rmdp-header {
  div {
    &:nth-child(1) {
      position: initial !important;
      align-items: initial !important;
    }
  }
}

.react-datepicker__day--selected {
  background: url(../img/orangetick.svg) !important;
  color: #fff !important;
  border: none !important;
  background-size: cover !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  color: #25272d !important;
  background: transparent !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #f2f2f3;
  color: #25272d;
  border: 1px solid #8f939b;
}

.react-datepicker__day,
.rmdp-day {
  width: 45px !important;
  height: 45px !important;
  line-height: 45px !important;
  border-radius: 8px !important;
  border: 1px solid transparent;
}

.rmdp-day span {
  width: 45px !important;
  height: 45px !important;
}

.react-datepicker__day--outside-month {
  color: #9c9d9f;
}

.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: none !important;
  padding: 0px 0 0 !important;
}

.react-datepicker__navigation,
.rmdp-arrow-container {
  width: 46px !important;
  height: 46px !important;
  top: 24px !important;
  border: 1px solid #f3f3f3 !important;
  border-radius: 10px;
  line-height: 40px;
  background: none !important;

  &.rmdp-left {
    position: relative;

    .rmdp-arrow {
      display: none;
    }

    &::before {
      content: "";
      background: url(../img/right-arrow.svg);
      position: absolute;
      width: 100%;
      height: 100%;
      transform: rotate(180deg);
      background-repeat: no-repeat;
      background-position: center;
    }

    &.disabled {
      &::before {
        filter: invert(1);
      }
    }
  }

  &.rmdp-right {
    position: relative;

    .rmdp-arrow {
      display: none;
    }

    &::before {
      content: "";
      background: url(../img/right-arrow.svg);
      position: absolute;
      width: 100%;
      height: 100%;

      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  top: 15px !important;
}

.react-datepicker__year-read-view--down-arrow {
  top: 2px !important;
}

.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  display: none;
}

.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  display: none !important;
}

.react-datepicker__navigation--next {
  right: 24px !important;
}

.react-datepicker__navigation--previous {
  left: 24px !important;
}

.react-datepicker__month-container {
  padding: 0 24px;
}

.react-datepicker__time-container,
.react-datepicker__time-box {
  width: 240px !important;
}

.react-datepicker-time__header {
  display: none;
}

.two-row-input .react-datepicker {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #8f939a;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #ed772e !important;
  color: #fff !important;
  background-image: url(../img/whitecheck.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 75% center;
  border: 1px solid transparent !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: #edeeee;
  color: #25272d;
  border: 1px solid #8f939a;
  border-radius: 4px;
}

.react-datepicker__day--today {
  background-color: #25272d !important;
  color: #fff !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: #fce4d6 !important;
  color: #000 !important;
}

.info_box {
  box-shadow: 0px 4px 32px 0px #0000001f;
  padding: 10px 12px;
  border-radius: 10px;
  background: #ececec;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  position: absolute;
  z-index: 4;
  width: 100%;
  margin-top: 4px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.rmdp-container {
  width: 100%;

  input {
    height: auto;
    position: relative;
  }
}

.custom_day {
  input {
    opacity: 0;
  }

  .text-danger {
    position: absolute;
  }
}

.rmdp-day {
  margin: 5px;

  span {
    border: 1px solid transparent !important;
    border-radius: 8px !important;
    position: initial;
    width: 36px;
    height: 36px;

    &:hover {
      border: 1px solid #8f939a !important;
      background: #f2f2f3 !important;
      color: #25272d !important;
    }
  }
}

.rmdp-shadow {
  box-shadow: 0px 4px 8px 0px #0000000f, 0px -4px 8px 0px #0000000f,
    4px 0px 8px 0px #0000000f, -4px 0px 8px 0px #0000000f,
    0px 0px 4px 0px #0000000a;
  border-radius: 8px;
}

.rmdp-calendar {
  padding: 24px;
  padding-top: 0;
}

.rmdp-today {
  span {
    background: #25272d !important;
    color: #fff !important;
  }
}

.rmdp-ym {
  height: initial;

  .rmdp-day {
    margin: 5px !important;

    span {
      width: 100% !important;
    }
  }
}

.rmdp-selected {

  span,
  span:hover {
    background: #ee7831 !important;
    color: #fff !important;
    box-shadow: none !important;
    border: none !important;
  }
}

.rmdp-arrow {
  height: 15px;
  margin-top: 10px;
  padding: 9px;
  width: 15px;
  border: solid #25272c;
  border-width: 0 2px 2px 0;
}

.rmdp-right i {
  margin-right: 5px;
}

.rmdp-left i {
  margin-left: 5px;
}

.rmdp-ep-arrow {
  display: none !important;
}

.rmdp-arrow-container:hover {
  background-color: transparent;
  box-shadow: none;

  .rmdp-arrow {
    border-color: #25272d;
  }
}

.rmdp-disabled,
.rmdp-deactive {
  background: #f3f3f3;
  color: #25272d;
}

.date_length {
  width: 100%;
  padding: 26px 12px 8px;
  padding-right: 45px;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  background: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #25272d;
  position: absolute;
  top: 0;
  height: 100%;
}

.event_page {
  padding: 0 !important;

  .select_listing_type {
    display: flex;
    justify-content: center;
    list-style: none;
    gap: 32px;
    padding-top: 24px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;

    li {
      cursor: pointer;
      color: #4e5158;
      padding-bottom: 9px;

      &.active {
        border-bottom: 1px solid #25272d;
        color: #25272d;
      }

      img {
        width: 24px;
      }
    }
  }

  .event_wrapper {
    padding: 0 60px;

    @media screen and (max-width: "900px") {
      padding: 0 40px;
    }
  }

  .event_title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 16px;
  }

  .event_subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .listings {
    display: grid;
    padding: 24px 0;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 20px;

    .listing_box {
      border: 1px solid #f2f2f2;
      border-radius: 12px;

      position: relative;

      .videoicon {
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        top: 65px;
      }

      .tag {
        position: absolute;
        left: -7px;
        top: 24px;
      }

      .event_img {
        height: 160px;
        width: 100%;
        border-radius: 12px 12px 0 0;
        object-fit: cover;
      }

      .span_box {
        position: relative;

        .distance {
          background: #00000052;
          padding: 4px 16px 4px 16px;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: right;
          color: #fff;
          position: absolute;
          top: -20px;
          left: 12px;
        }

        .price {
          background: linear-gradient(167.07deg,
              #ee7830 -15.49%,
              #ee762f 149.41%);
          border-radius: 8px;
          padding: 4px 16px 4px 16px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          color: #fff;
          position: absolute;
          right: 12px;
          top: -20px;
        }
      }

      .title {
        display: inline-block;
        width: 200px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 12px;
        min-height: 40px;
        margin-top: 16px;
      }

      .others {
        display: flex;
        justify-content: space-between;
        padding: 0 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;

        .time {
          display: flex;
          align-items: center;
          gap: 4px;
          color: #8f939a;
        }

        .remaining {
          display: flex;
          align-items: center;
          gap: 2px;
          color: #8f939a;
        }
      }

      .name {
        background: #f3f3f3;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        padding: 10px 12px;
        margin-top: 14px;
        border-radius: 0 0 12px 12px;
        display: flex;
        align-items: center;
        gap: 2px;

        span {
          white-space: nowrap;
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }

        img,
        video {
          width: 20px;
          height: 20px;
          object-fit: cover;
          margin-right: 5px;
          vertical-align: middle;
          border-radius: 100%;
          box-shadow: 0px 3.3333332538604736px 10px 0px #0000001f;
        }
      }

      .name-special {
        background-color: #ee7830;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: unset;
          height: unset;
          object-fit: unset;
          margin-right: unset;
          vertical-align: unset;
          border-radius: unset;
          box-shadow: unset;
        }
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .event_page {
    .select_listing_type {
      justify-content: start;
      padding-left: 1rem;
    }

    .event_wrapper {
      padding: 1rem;

      .event_title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        padding-top: 16px;
        color: var(--SteelBlue, #25272d);
        font-style: normal;
      }

      .event_subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #535a67;
        font-style: normal;
      }

      .listings {
        display: grid;
        padding: 24px 0;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        gap: 20px;

        .listing_box {
          .name {
            span {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: "478px") {
  .event_page {
    .event_wrapper {
      padding: 1rem;

      .listings {
        display: grid;
        padding: 24px 0;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        gap: 20px;

        .listing_box {
          .name {
            width: 100%;

            span {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.draft_btn {
  width: 186px;
  border-radius: 130px;
  position: fixed;
  z-index: 20;
  bottom: 100px;
}

.add-tick {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url(../img/blacktick.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 95% 7% !important;
    right: 3px;
    top: 2px;
  }
}

.page_loader {
  display: flex;
  align-items: center;
  min-height: 500px;
}

.reset_form {
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #ee7830;

  span {
    cursor: pointer;
  }
}

.draft_popup {
  max-width: 390px !important;
  gap: 30px !important;
  padding: 20px !important;

  .action_btns {
    display: grid;
    gap: 10px;

    button {
      text-align: center !important;
      height: 56px;
      padding: 10px 24px !important;
      border-radius: 12px;
      border: 1px solid #f3f3f3;
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
      color: #25272d;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      position: relative;

      img {
        position: absolute;
        left: 15px;
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .draft_popup {
    max-width: 390px !important;
    gap: 30px !important;
    padding: 20px !important;

    .modal-top-part {
      margin-bottom: 16px;
    }
  }
}

.position-initial {
  position: initial;
}

.position-relative {
  position: relative;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end {
  color: #fff !important;
  background: linear-gradient(167.07deg,
      #ee7830 -15.49%,
      #ee762f 149.41%) !important;
}

.no_cross {
  .cross-icon {
    display: none;
  }

  display: flex;
  gap: 16px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 16px;

    .form_group {
      margin-bottom: 0;
    }
  }
}

.interest-error {
  margin-top: -20px;
}

// View Listing Page
.view-listing-page-wrap {
  background: #f9f9f9;
  padding: 60px 130px 60px 130px;
  margin-top: 94px;
  min-height: calc(100vh - 90px);

  .alert-text-wrapper {
    margin-top: 13px;
    max-width: 1011px;
    background-color: #f3f3f3;
    padding: 10px 12px 10px 12px;
    color: #25272d;
    border-radius: 10px;
    display: flex;
    gap: 4px;
    font-size: 14px;
    font-weight: 400;
  }

  .view-linsting-white {
    // max-width: 1112px;
    background-color: #fff;
    border-radius: 12px;
    padding: 24px 34px 20px 32px;
    // margin-bottom: 32px;
    overflow-x: scroll;
    border: 1px solid #dcdcdc;

    &::-webkit-scrollbar {
      display: none;
    }

    .graph-section-top {
      display: flex;
      justify-content: space-between;

      .year-select-box {
        padding: 8px;
        outline: none;
        border: none;
        background: #f3f3f3;
        border-radius: 8px;
        height: 40px;
      }

      .right {
        display: flex;
        gap: 12px;
        align-items: center;
      }

      .export-btn {
        border-radius: 130px;
        width: 130px;
        display: inline-flex;
        padding: 12px 20px 12px 16px;
        justify-content: center;
        align-items: center;
      }
    }

    .sp-export-btn {
      border-radius: 130px;
      width: 130px;
      display: inline-flex;
      padding: 12px 20px 12px 16px;
      justify-content: center;
      align-items: center;
    }

    .s-booking-types {
      display: flex;
      margin-top: 32px;
      gap: 8px;

      .sb-type {
        width: 100px;
        color: #8f939a;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;

        p {
          margin-bottom: 8px;
          cursor: pointer;
        }

        .s-active-line {
          height: 2px;
          background-color: #ee7830;
        }
      }

      .sb-type.active {
        color: #25272d;
      }
    }

    .sb-booking-container {
      .sb-archives-container {
        .sb-archive {
          display: flex;
          gap: 12px;
          margin-bottom: 32px;

          .sb-date-cont {
            .sb-day {
              color: #8f939a;

              font-size: 12px;

              font-weight: 500;
              line-height: 16px;
            }
          }

          .sb-archive-card {
            width: 100%;
            border-radius: 12px;
            border: 1px solid #f2f2f2;
            background: #fff;
            box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.04);
            padding: 12px;

            .sb-card-title {
              color: #8f939a;
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              width: 200px;
              white-space: nowrap;

              p {
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 12px;
              }
            }

            .sb-card-location {
              display: flex;
              gap: 4px;

              p {
                color: #8f939a;
                font-weight: 400;
                font-size: 14px;
                margin-bottom: 0px;
              }
            }

            .sb-card-time-participants {
              display: flex;
              gap: 24px;

              .sb-time {
                display: flex;
                color: #8f939a;
                font-size: 400;
                align-items: center;
                gap: 4px;

                p {
                  margin-bottom: 0px;
                }
              }

              .avatar-participants-container {
                display: flex;
                gap: 4px;
                justify-content: center;
                align-items: center;

                .avatar-container {
                  padding-left: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  overflow: hidden;

                  .avatar-item {
                    margin-left: -10px;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                      width: 24px;
                      height: 24px;
                      border-radius: 50%;
                    }
                  }
                }

                .t-participants {
                  text-align: end;
                  color: var(--SteelBlue, #25272d);
                  font-size: 0.75rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.25rem;
                }
              }
            }
          }
        }

        .sb-hr {
          margin-left: -32px;
          margin-right: -32px;
        }
      }
    }
  }

  .section-title {
    color: var(--SteelBlue, #25272d);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .section-sub-title {
    color: #8f939a;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .mt-28 {
    margin-top: 28px;
  }

  .card-list {
    display: grid;
    padding: 24px 0;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;

    .listing_box {
      border: 1px solid #f2f2f2;
      border-radius: 12px;
      position: relative;
      width: 287px;

      .videoicon {
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        top: 65px;
      }

      .tag {
        position: absolute;
        left: -7px;
        top: 24px;
      }

      .event_img {
        height: 160px;
        width: 100%;
        border-radius: 12px 12px 0 0;
        object-fit: cover;
      }

      .span_box {
        position: relative;

        .distance {
          background: #00000052;
          padding: 4px 16px 4px 16px;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: right;
          color: #fff;
          position: absolute;
          top: -20px;
          left: 12px;
        }

        .price {
          background: linear-gradient(167.07deg,
              #ee7830 -15.49%,
              #ee762f 149.41%);
          border-radius: 8px;
          padding: 4px 16px 4px 16px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          color: #fff;
          position: absolute;
          right: 12px;
          top: -20px;
        }
      }

      .title {
        display: inline-block;
        width: 200px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 12px;
        min-height: 40px;
        margin-top: 16px;
      }

      .others {
        display: flex;
        justify-content: space-between;
        padding: 0 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;

        .time {
          display: flex;
          align-items: center;
          gap: 4px;
          color: #8f939a;
        }

        .remaining {
          display: flex;
          align-items: center;
          gap: 2px;
          color: #8f939a;
        }
      }

      .e-info-container {
        width: 63%;

        @media screen and (max-width: 480px) {
          width: 100%;
        }

        @media screen and (max-width: 768px) {
          width: 80%;
        }

        .dropdown-container {
          display: flex;
          justify-content: end;
          margin-bottom: 1rem;
          width: 100%;

          .drop-cont {
            position: relative;

            .dropdown-btn {
              display: flex;
              padding: 0.25rem 1rem;
              gap: 0.25rem;
              border-radius: 6.25rem;
              background: rgba(0, 0, 0, 0.32);
              backdrop-filter: blur(16px);
            }

            .drop-menu {
              position: absolute;
              border: 1px solid blue;
              right: 0;
              top: 50%;
              width: 14.4375rem;
              height: 8.5rem;
              flex-shrink: 0;
              border-radius: 0.75rem;
              border: 1px solid var(--FitnessNonSelected, #f3f3f3);
              background: #fff;
              box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);

              .drop-menu-content {
                padding: 1rem;

                .menu-item {
                  display: flex;
                  margin-bottom: 1rem;
                  align-items: center;
                  gap: 0.75rem;
                }
              }
            }
          }
        }

        .info-container {
          .i-title-container {
            width: 100%;
          }

          .info-title {
            color: var(--SteelBlue, #25272d);
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            word-wrap: break-word;
          }

          .buttons-group {
            margin-top: 1.12rem;

            display: flex;
            gap: 0.75rem;
            margin-bottom: 1rem;
            flex-wrap: wrap;

            .listing-btn {
              display: flex;
              padding: 0.25rem 1rem 0.25rem 0.625rem;
              justify-content: center;
              align-items: center;
              gap: 0.25rem;
              border-radius: 0.5rem;
              border: 1px solid #f3f3f3;
              background: var(--Orange-Gradient,
                  linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));

              .list-btn-text {
                color: var(--FitnessWhite, #fff);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1rem;
              }
            }

            .time-btn {
              display: flex;
              padding: 0.25rem 0.875rem 0.25rem 0.75rem;
              justify-content: center;
              align-items: center;
              gap: 0.25rem;
              border-radius: 0.5rem;
              border: 1px solid #f3f3f3;
              background: var(--fitness-mates-white, #fff);

              .time-btn-text {
                color: var(--SteelBlue, #25272d);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.25rem;
              }
            }

            .inter-btn {
              display: flex;
              padding: 0.25rem 0.875rem 0.25rem 0.75rem;
              justify-content: center;
              align-items: center;
              gap: 0.25rem;
              border-radius: 0.5rem;
              border: 1px solid #f3f3f3;
              background: var(--fitness-mates-white, #fff);

              .inter-btn-text {
                color: var(--SecondarySteel, #8f939a);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.25rem;
              }
            }
          }
        }

        .remaining {
          display: flex;
          align-items: center;
          gap: 2px;
          color: #8f939a;
        }
      }
    }

    .activeList {
      margin-top: 16px;
      border-radius: 0px 0px 12px 12px;
      background: #f1fff7;
      color: #40b67d;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    .inactiveList {
      margin-top: 16px;
      padding: 10px;
      border-radius: 0px 0px 12px 12px;
      background: #ffe2e2;
      color: #eb5757;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    .more-icon-item {
      display: flex;
      align-items: center;
      color: #25272d;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
      gap: 32px;
      margin-bottom: 16px;
      cursor: pointer;
    }
  }

  .more-popup-listing {
    background: rgba(0, 0, 0, 0.32);
    position: absolute;
    top: 12px;
    right: 12px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .more-popup-item-wrap {
    background: #fff;
    padding: 16px;
    position: absolute;
    top: 54px;
    right: 9px;
    width: 230px;
    max-height: 90px;
    border-radius: 12px;
  }

  //cancel card
  .upcoming-schedule-row-outer {
    display: flex;

    gap: 24px;
    padding: 24px 16px;

    &:not(:last-child) {
      border-bottom: 1px solid #f3f3f3;
    }

    .upcoming-schedule-wrap {
      border-radius: 12px;
      border: 1px solid var(--Gray-6, #f2f2f2);
      background: #fff;
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.04);
      padding: 12px;
      max-width: 668px;
      flex-grow: 1;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .card-content {
        display: flex;
        align-items: center;
        gap: 24px;

        .left {
          .title {
            max-width: 373px;
            overflow: hidden;
            color: var(--FitnessMates-Pitch-Deck-Black, #25272d);
            text-overflow: ellipsis;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
          }

          .content-items {
            display: flex;
            align-items: center;
            gap: 6px;
          }
        }
      }

      .left-content {
        display: flex;
        align-items: center;
      }

      .sub-content-wrap {
        margin-top: 12px;
        display: flex;
        gap: 30px;

        .content-items {
          display: flex;
          align-items: center;
          line-height: 0;
          gap: 8px;
        }
      }

      .day {
        overflow: hidden;
        color: #8f939a;
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 8px;
      }

      .date {
        color: #25272d;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .title {
        overflow: hidden;
        color: #25272d;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 8px;
      }

      .t-sub-title-box {
        width: 16rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .sub-title {
        color: #8f939a;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .map-link {
        color: #ee7830;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-decoration-line: underline;
        cursor: pointer;
      }

      .vertical-divider {
        margin: 8px 24px;
        width: 1px;
        background: #ebebeb;
        min-height: 72px;
      }

      .avatar-participants-container {
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        .avatar-container {
          padding-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          .avatar-item {
            margin-left: -10px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
            }
          }
        }

        .t-participants {
          text-align: end;
          color: var(--SteelBlue, #25272d);
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.25rem;
        }
      }
    }

    .sb-date-cont {
      .sb-day {
        color: var(--SecondarySteel, #8f939a);
        text-align: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }

      .sb-date {
        color: var(--SteelBlue, #25272d);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .refund-status-box {
      height: 48px;
      padding: 4px 12px;
      border-radius: 8px;

      .title {
        color: var(--SecondarySteel, #8f939a);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        margin: 0;
      }

      .status {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }

    .cancelled-card-wrap {
      justify-content: space-between;
      align-items: flex-start !important;
    }
  }

  @media (max-width: 768px) {
    .upcoming-schedule-row-outer {
      padding: 0px;
    }
  }
}

.map-fixed-sidebar {
  position: fixed;
  background: #25272dcc;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 99;

  .map-sidebar-box {
    background: #ffffff;
    max-width: 610px;
    height: 100%;

    overflow: auto;
    margin-left: auto;

    .map-heading {
      margin-left: 32px;
      margin-top: 100px;
      display: flex;
      gap: 16px;

      img {
        cursor: pointer;
      }

      p {
        color: var(--SteelBlue, #25272d);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 0px;
      }
    }

    .sb-map-container {
      height: calc(100% - 140px);
      margin-top: 20px;

      .map-back-icon {
        position: absolute;
        z-index: 99999;
        cursor: pointer;
        left: 16px;
        top: 80px;
      }
    }
  }
}

.booking_details_wrapper {
  .sb-map-container {
    height: calc(100% - 73.33px) !important;
    margin-top: 20px;

    .map-back-icon {
      position: absolute;
      z-index: 99999;
      cursor: pointer;
      left: 16px;
      top: 80px;
    }
  }
}

.sb-map-container-new {
  height: calc(100% - 73.33px) !important;
}

@media screen and (max-width: "768px") {
  .view-listing-page-wrap {
    padding: 16px 20px 16px 20px;

    .upper-title {
      display: flex;
      justify-content: space-between;

      .listing-title {
        color: var(--SteelBlue, #25272d);
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
      }

      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .alert-text-wrapper {
      margin-top: 13px;
      max-width: 350px;
      background-color: #f3f3f3;
      padding: 10px 12px 10px 12px;
      color: #25272d;
      border-radius: 10px;
      display: flex;
      gap: 4px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      align-items: start;
    }

    .view-linsting-white {
      padding: 1rem;
      margin-bottom: 1rem;

      .s-booking-types {
        justify-content: space-around;

        .sb-type {
          width: 100%;
        }
      }

      .section-title {
        margin-top: 24px;
        color: var(--SteelBlue, #25272d);
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .section-sub-title {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .carousel-container {
        .next-button {
          display: none;
        }

        .prev-button {
          display: none;
        }
      }

      .sb-booking-container {
        .sb-archives-container {
          .sb-hr {
            margin-left: -1rem;
            margin-right: -1rem;
          }

          .sb-archive {
            .sb-archive-card {
              .sb-card-time-participants {
                flex-direction: column;

                .avatar-participants-container {
                  justify-content: start;
                }
              }
            }
          }
        }
      }
    }
  }

  .map-fixed-sidebar {
    .map-sidebar-box {
      .sb-map-container {
        margin-top: 0px;
        height: calc(100% - 73.33px);
      }
    }
  }

  .created-listing-btn {
    display: none;
  }
}

@media screen and (max-width: "478px") {
  .view-listing-page-wrap {
    padding: 16px 20px 16px 20px;

    .view-linsting-white {
      padding: 1rem;
      margin-bottom: 1rem;

      .carousel-container {
        .next-button {
          display: none;
        }

        .prev-button {
          display: none;
        }
      }
    }

    // .section-title {
    //   // text-align: left;

    // }

    .created-listing-btn {
      display: none;
    }
  }
}

.add_listing_btn {
  width: 187px;
  border-radius: 130px;
  height: 52px;
  z-index: 1500;
  font-weight: 600;
}

@media screen and (max-width: "478px") {
  .add_listing_btn {
    width: 162px;
    height: 48px;
    font-size: 14px;
    z-index: 1500;
  }
}

/* Card.css */
.card {
  width: 200px;
  height: 300px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

//myschedule card

.my-schedule-wrap {
  border-radius: 12px;
  border: 1px solid #dbdbdb;
  background: #fff;
  min-height: 88px;
  // padding: 0 16px;
  padding: 11px 70px 14px 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .left-content {
    display: flex;
    align-items: center;
  }

  .sub-content-wrap {
    margin-top: 12px;
    display: flex;
    gap: 30px;

    .content-items {
      display: flex;
      align-items: center;
      line-height: 0;
      gap: 8px;
    }
  }

  .day {
    overflow: hidden;
    color: #8f939a;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .date {
    color: #25272d;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .title {
    overflow: hidden;
    color: #25272d;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    // margin-bottom: 8px;
  }

  .t-sub-title-box {
    width: 4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sub-title {
    color: #8f939a;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .map-link {
    color: #ee7830;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .vertical-divider {
    margin: 8px 24px;
    width: 1px;
    background: #ebebeb;
    min-height: 72px;
  }

  .avatar-participants-container {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    .avatar-container {
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .avatar-item {
        margin-left: -10px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
      }
    }

    .t-participants {
      text-align: end;
      color: var(--SteelBlue, #25272d);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
    }
  }
}

@media screen and (max-width: "768px") {
  .my-schedule-wrap {
    display: block;
    padding: 16px;

    .left-content {
      align-items: start;
      margin-top: 1rem;
    }

    .vertical-divider {
      margin: 0px 11px 0px 24px;
    }

    .sub-content-wrap {
      flex-wrap: wrap;
      gap: 8px;
    }

    .title {
      overflow: hidden;
      color: var(--fitness-mates-pitch-deck-black, #25272d);
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.t-schedule-month {
  overflow: hidden;
  color: var(--SteelBlue, #25272d);
  text-overflow: ellipsis;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

/* OutlineButton.css */
.outline-button {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid #ee7830;
  color: #ee7830;
  background: linear-gradient(167deg,
      rgba(238, 120, 48, 0.12) -15.49%,
      rgba(238, 118, 47, 0.12) 149.41%),
    #fff;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  justify-content: center;
}

.outline-button:hover {
  background-color: rgba(238, 120, 48, 0.1);
  /* Light shade of the button color */
}

.outline-button>span {
  margin-left: 4px;
}

.event-link-title {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  // height: 100%;
}

.dyevent-container {
  width: 100%;
  background: #f6f6f6;
  padding-top: 1.6rem;
  margin-top: 94px;
}

.dyevent-main-container {
  margin: auto;
  margin-top: 0px;
  padding: 2rem;
  width: 85%;
  border-radius: 12px;
  background: var(--FitnessWhite, #fff);
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  max-width: 1112px;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    padding: 1rem;
    width: 100%;
    padding: 0rem;
  }

  .eimage-container {
    width: 390px;
    border-radius: 12px;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;

    .e-member-only {
      display: flex;
      width: 100%;
      gap: 15px;
      align-items: center;
      background-color: rgba(238, 120, 48, 0.1);
      padding: 10px 18px;
      border-radius: 12px;
      margin-top: 16px;

      .icon {
        background-color: #ee762f;
        border-radius: 100%;
        padding: 4px;
        display: flex;
      }

      p {
        margin-bottom: 0px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    .cancel_booking_buttons_wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 32px;

      .c_b_info {
        display: flex;
        gap: 4px;
        padding: 10px 12px;

        border-radius: 10px;
        background: #f3f3f3;
        align-items: start;

        .c_b_txt {
          color: var(--SecondarySteel, #8f939a);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .c_b_cancel {
        background: #ebebeb;
        color: #8f939a;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .c-cancellation-policy {
      .c-first {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 0px;
      }

      .c-second {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    img {
      border-radius: 12px;
      max-width: 100%;
      min-width: 200px;
      margin: 0 auto;
    }

    .all-button {
      padding: 0 0px;

      width: 100%;
      margin-top: 1rem;

      .all-button-top {
        display: flex;
        gap: 15px;

        .event_price {
          color: var(--SteelBlue, #25272d);
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }

        .event_class {
          color: var(--SteelBlue, #25272d);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          display: flex;
          flex-shrink: 0;
        }

        .event_btn_price {
          max-width: 200px;
          width: 100%;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #25272d;
          justify-content: center;
          background: transparent;
          border: 0;
          flex: 1;

          .common_btn {
            width: 100%;
            flex: 3;
          }
        }

        .common_btn {
          width: 100%;
          flex: 1;
        }
      }

      .c-cancellation-policy {
        .c-first {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 0px;
        }

        .c-second {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }

  .e-info-container {
    width: 63%;

    .cancel_booking_buttons_wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 32px;

      .c_b_info {
        display: flex;
        gap: 4px;
        padding: 10px 12px;

        border-radius: 10px;
        background: #f3f3f3;
        align-items: start;

        .c_b_txt {
          color: var(--SecondarySteel, #8f939a);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .c_b_cancel {
        background: #ebebeb;
        color: #8f939a;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      .c-cancellation-policy {
        .c-first {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 0px;
        }

        .c-second {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .all-button {
      padding: 0 20px;

      width: 100%;
      margin-top: 1rem;

      .all-button-top {
        display: flex;
        gap: 15px;

        .event_price {
          color: var(--SteelBlue, #25272d);
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }

        .event_class {
          color: var(--SteelBlue, #25272d);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          display: flex;
          flex-shrink: 0;
        }

        .event_btn_price {
          max-width: 200px;
          width: 100%;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #25272d;
          justify-content: center;
          background: transparent;
          border: 0;
          flex: 2;

          .common_btn {
            width: 100%;
            flex: 3;
          }
        }
      }

      .c-cancellation-policy {
        .c-first {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 0px;
        }

        .c-second {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      width: 80%;
    }

    .dropdown-container {
      display: flex;
      justify-content: end;
      margin-bottom: 1rem;
      width: 100%;

      .drop-cont {
        position: relative;

        .dropdown-btn {
          display: flex;
          padding: 0.25rem 1rem;
          gap: 11px;
          border-radius: 6.25rem;
          background: rgba(0, 0, 0, 0.32);
          backdrop-filter: blur(16px);
          align-items: center;
        }

        .dropdown-btn>* {
          cursor: pointer;
        }

        .drop-menu {
          position: absolute;
          border: 1px solid blue;
          right: 0;
          top: 50%;
          width: 14.4375rem;
          height: 8.5rem;
          flex-shrink: 0;
          border-radius: 0.75rem;
          border: 1px solid var(--FitnessNonSelected, #f3f3f3);
          background: #fff;
          box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);

          .drop-menu-content {
            padding: 1rem;

            .menu-item {
              display: flex;
              margin-bottom: 1rem;
              align-items: center;
              gap: 0.75rem;
            }
          }
        }
      }
    }

    .mobile-buttons-group {
      margin-top: -2rem;

      display: flex;
      gap: 0.75rem;
      margin-bottom: 1rem;
      flex-wrap: wrap;

      .color_black {
        background: black !important;
      }

      .listing-btn {
        display: flex;
        padding: 0.25rem 1rem 0.25rem 0.625rem;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
        border-radius: 0.5rem;
        border: 1px solid #f3f3f3;
        background: var(--Orange-Gradient,
            linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));

        .list-btn-text {
          color: var(--FitnessWhite, #fff);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1rem;
        }
      }

      .time-btn {
        display: flex;
        padding: 0.25rem 0.875rem 0.25rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
        border-radius: 0.5rem;
        border: 1px solid #f3f3f3;
        background: var(--fitness-mates-white, #fff);

        .time-btn-text {
          color: var(--SteelBlue, #25272d);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.25rem;
        }
      }

      .inter-btn {
        display: flex;
        padding: 0.25rem 0.875rem 0.25rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
        border-radius: 0.5rem;
        border: 1px solid #f3f3f3;
        background: var(--fitness-mates-white, #fff);

        .inter-btn-text {
          color: var(--SecondarySteel, #8f939a);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.25rem;
        }
      }
    }

    .info-container {
      .i-heading-container {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        .info-title {
          color: var(--SteelBlue, #25272d);
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
        }

        .msg_badge {
          .badge_txt {
            background-color: #ee7830;
            font-size: 10px;
            top: -10px;
            right: -10px;
            color: #fff;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }

      .buttons-group {
        margin-top: 1.12rem;

        display: flex;
        gap: 0.75rem;
        margin-bottom: 1rem;
        flex-wrap: wrap;

        .listing-btn {
          display: flex;
          padding: 0.25rem 1rem 0.25rem 0.625rem;
          justify-content: center;
          align-items: center;
          gap: 0.25rem;
          border-radius: 0.5rem;
          border: 1px solid #f3f3f3;
          background: var(--Orange-Gradient,
              linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));

          .list-btn-text {
            color: var(--FitnessWhite, #fff);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1rem;
          }
        }

        .color_black {
          background: black !important;
        }

        .time-btn {
          display: flex;
          padding: 0.25rem 0.875rem 0.25rem 0.75rem;
          justify-content: center;
          align-items: center;
          gap: 0.25rem;
          border-radius: 0.5rem;
          border: 1px solid #f3f3f3;
          background: var(--fitness-mates-white, #fff);

          .time-btn-text {
            color: var(--SteelBlue, #25272d);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem;
          }
        }

        .inter-btn {
          display: flex;
          padding: 0.25rem 0.875rem 0.25rem 0.75rem;
          justify-content: center;
          align-items: center;
          gap: 0.25rem;
          border-radius: 0.5rem;
          border: 1px solid #f3f3f3;
          background: var(--fitness-mates-white, #fff);

          .inter-btn-text {
            color: var(--SecondarySteel, #8f939a);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem;
          }
        }

        .avatar-participants-container {
          display: flex;
          gap: 1rem;
          justify-content: center;
          align-items: center;

          .avatar-container {
            padding-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .avatar-item {
              margin-left: -10px;
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
              }
            }
          }

          .t-participants {
            text-align: end;
            color: var(--SteelBlue, #25272d);
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem;
          }

          .avatar-info {
            color: var(--SecondarySteel, #8f939a);
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            span {
              color: var(--SteelBlue, #25272d);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
            }
          }
        }
      }
    }

    .dates-container {
      margin-bottom: 1.75rem;

      .dates-button {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .dates-btn-txt {
          color: var(--SteelBlue, #25272d);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.5rem;
          gap: 10px;

          .left_out {
            color: #8f939a;

            span {
              color: var(--SteelBlue, #25272d);
            }
          }
        }

        .view-margin-right {
          margin-right: 3rem;

          @media screen and (max-width: 480px) {
            margin-right: 0rem;
          }

          @media screen and (max-width: 980px) {
            margin-right: 1rem;
          }
        }
      }

      .dates-info-container {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        .dates-info {
          display: flex;
          padding: 0.5rem 0.5rem 0.5rem 0.5rem;
          flex-direction: column;
          align-items: flex-start;

          border-radius: 0.75rem;
          border: 1px solid #dddddd;
          max-width: 207px;
          flex-grow: 1;

          .dates-info-up-txt {
            color: var(--SteelBlue, #25272d);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
          }

          .booking_full {
            color: var(--SteelBlue, #25272d);
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }

          .dates {
            display: flex;

            .date-hl {
              color: var(--SecondarySteel, #8f939a);
              text-align: right;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.25rem;
              display: flex;
              align-items: center;
            }

            .date-txt {
              color: var(--SecondarySteel, #8f939a);
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
            }
          }
        }

        .active-date {
          border: 1px solid #ee7830;
        }

        .session_p {
          display: flex;
          min-width: 291px;

          padding: 12px 12px 12px 12px;
          flex-direction: column;

          gap: 8px;

          border-radius: 12px;
          border: 1px solid #dddddd;
          background: var(--FitnessWhite, #fff);
          flex: 1;

          .sp_title {
            color: var(--SteelBlue, #25272d);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            width: 100%;
            white-space: nowrap;
            /* Prevents the text from wrapping to the next line */
            overflow: hidden;
            /* Hides any content that overflows the container */
            text-overflow: ellipsis;
          }

          .sp_address {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;

            p {
              color: var(--SecondarySteel, #8f939a);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;

              white-space: nowrap;
              /* Prevents the text from wrapping to the next line */
              overflow: hidden;
              /* Hides any content that overflows the container */
              text-overflow: ellipsis;
            }
          }

          .sp_date_time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(--SecondarySteel, #8f939a);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            div {
              display: flex;

              align-items: center;
              gap: 5px;
            }
          }
        }
      }
    }

    .l-special {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      color: #25272d;
    }

    .l-instructions-wrapper {
      // border: 2px solid green;

      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 24px;

      .l-instruction {
        padding: 8px 12px 8px 8px;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        min-width: 47%;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #25272d;
      }
    }

    .review-container {
      display: flex;
      justify-content: space-between;

      .review {
        color: var(--SteelBlue, #25272d);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      .view-all {
        display: flex;
        gap: 10px;
        color: var(--SteelBlue, #25272d);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;
      }
    }

    .desc-container {
      width: 95%;
      margin-bottom: 1.5rem;

      .desc {
        color: var(--SteelBlue, #25272d);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        white-space: pre-line;
      }
    }

    .user-container {
      display: flex;
      gap: 5px;
      margin-bottom: 1.5rem;
      align-items: center;

      .user-image-container {
        display: flex;
        align-items: center;

        img {
          border-radius: 50%;
          border: 3px solid #f3f3f3;
          width: 44px;
          height: 44px;
        }

        video {
          border-radius: 50%;
          border: 3px solid #f3f3f3;
          width: 44px;
          height: 44px;
          object-fit: cover;
        }
      }

      .user-info-container {
        .user-name-txt {
          color: var(--SteelBlue, #25272d);
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.5rem;
        }

        .user-rating-container {
          display: flex;
          color: var(--SteelBlue, #25272d);
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 500;
          line-height: 0.75rem;

          .user-rate-hl {
            color: var(--Gray-4, #bdbdbd);
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.75rem;
            padding-left: 5px;
          }
        }
      }
    }

    .interest-container {
      margin-bottom: 1.5rem;

      .int-txt {
        color: var(--SteelBlue, #25272d);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
      }

      .interest-buttons-group {
        display: flex;
        gap: 5px;
        margin-top: 0.75rem;
        flex-wrap: wrap;

        .interest-btn {
          display: flex;
          padding: 0.5rem 0.5rem 0.5rem 0.75rem;
          justify-content: center;
          align-items: center;
          gap: 0.25rem;
          border-radius: 0.5rem;
          border: 1px solid var(--Gray-5, #e0e0e0);

          .int-txt {
            color: #76777c;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem;
          }
        }
      }
    }

    .map-container {
      width: 100%;

      .map-txt {
        margin-bottom: 0.75rem;
        color: var(--SteelBlue, #25272d);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
      }

      .map-img-container {
        width: 100%;
        border-radius: 12px;

        img {
          width: 100%;
          border-radius: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .dyevent-container {
    width: 100%;
    background: #f6f6f6;
    padding-top: 1rem;
    margin-top: 73.33px;
  }

  .dyevent-main-container {
    flex-wrap: wrap;
    padding: 1rem;
    width: 100%;
    padding: 0rem;
    gap: 0rem;
    margin-top: 0px;

    .eimage-container {
      width: 100%;

      .back_arrow {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.32);
        border-radius: 50%;
        top: 16px;
        left: 16px;
        display: flex;
        padding: 3px;
      }

      img {
        border-radius: 0px;
        height: 428px;
        object-fit: cover;
      }
    }

    .e-info-container {
      width: 100%;
      padding: 1rem;

      .dropdown-container {
        position: absolute;
        top: 16px;
        right: 10px;
      }

      .dates-container {
        .dates-info-container {
          flex-wrap: nowrap;
          overflow: scroll;

          .dates-info {
            flex-shrink: 0;
          }
        }
      }

      .e-member-only {
        display: flex;
        width: unset;
        gap: 15px;
        align-items: center;
        background-color: rgba(238, 120, 48, 0.1);
        padding: 10px 18px;
        border-radius: 12px;
        margin: 16px -16px;

        .icon {
          background-color: #ee762f;
          border-radius: 100%;
          padding: 4px;
          display: flex;
        }

        p {
          margin-bottom: 0px;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .info-container {
        .i-heading-container {
          .info-title {
            font-size: 20px;
            margin-bottom: 0px;
          }
        }

        .buttons-group {
          margin-top: 12px;

          .inter-btn {
            .inter-btn-text {
              font-size: 12px;
            }
          }
        }
      }

      .all-button {
        padding: 0 0;

        .all-button-top {
          .event_btn_price {
            flex: 1;
          }

          .common_btn {
            flex: 1;
          }
        }
      }
    }
  }
}

.events-filter {
  position: absolute;
  right: 116px;
  top: 42px;
  display: flex;
  gap: 12px;

  @media screen and (max-width: "900px") {
    right: 95px;
  }

  .sorting-button {
    background: transparent;
    border-radius: 12px;
    border: 1px solid rgba(37, 39, 45, 1);
    padding: 10px 20px;
    display: flex;
    gap: 10px;
    cursor: pointer;
  }

  .filter-button {
    border-radius: 12px;
    padding: 12px;
    border: 0;
    display: grid;
    cursor: pointer;
  }

  .filter_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
}

@media screen and (max-width: "768px") {
  .events-filter {
    right: 16px;
    top: 40px;

    .sorting-button {
      padding: 5px 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.event-filter-outer {
  position: relative;

  .filter-dropdown {
    background: #fafafa;
    border: 1px solid rgba(143, 147, 154, 0.08);
    border-radius: 12px;
    min-width: 247px;
    right: 0;

    list-style: none;
    padding: 25px;
    position: absolute;
    z-index: 4;
    top: 60px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      width: 100%;
      position: relative;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0px;
      }

      div {
        display: flex;
      }

      img {
        width: 35px;
        height: 20px;
        margin-right: 17px;
      }

      .select_img {
        position: absolute;
        right: -5px;
        margin-right: 0 !important;
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #25272d;
      }

      input {
        width: 20px;
        height: 20px;
      }
    }

    li.active {
      span {
        color: #ee762f;
        font-weight: 600;
      }
    }
  }
}

// Right sidebar

.fixed-sidebar-right {
  position: fixed;
  background: #25272dcc;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1000;

  .sidebar-box {
    background: #ffffff;
    max-width: 550px;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
    margin-right: auto;
    padding: 36px 20px;

    .close-btn {
      cursor: pointer;
    }

    .inner-contet {
      max-width: 452px;
      margin: 0 auto;
      width: 100%;
      margin-top: 42px;

      .filter-heading {
        color: #25272d;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 24px;
        text-align: left;
      }

      .filter-items {
        color: #25272d;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 16px 0px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
          border-bottom: 1px solid #f3f3f3;
        }
      }
    }
  }
}

.fixed-sidebar-left {
  position: fixed;
  background: #25272dcc;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;

  .sidebar-box {
    background: #ffffff;
    max-width: 1140px;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
    margin-left: auto;
    padding: 36px 20px;

    .close-btn {
      cursor: pointer;
    }

    .inner-contet {
      max-width: 1100px;
      margin: 0 auto;
      width: 100%;
      margin-top: 42px;
    }
  }

  .sidebar-box-event {
    background: #ffffff;
    max-width: 610px;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
    margin-left: auto;
    padding: 32px;

    .event-title {
      margin-top: 70px;
      display: flex;
      gap: 22px;
      align-items: center;
      color: var(--SteelBlue, #25272d);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      .close-btn {
        cursor: pointer;
      }
    }

    .inner-contet {
      max-width: 580px;
      margin: 0 auto;
      width: 100%;
      margin-top: 32px;
    }
  }
}

@media screen and (max-width: "768px") {
  .fixed-sidebar-left {
    background-color: transparent;
    top: 10px;

    .sidebar-box-event {
      background: #ffffff;
      max-width: 610px;
      height: 100%;
      max-height: 100vh;
      overflow: auto;
      margin-left: auto;
      padding: 1rem;
      top: 0px;
      margin-top: 1rem;
      position: relative;

      .event-title {
        margin-top: 0px;
        display: flex;
        gap: 22px;
        align-items: center;
        color: var(--SteelBlue, #25272d);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        .close-btn {
          cursor: pointer;
        }
      }

      .inner-contet {
        max-width: 580px;
        margin: 0 auto;
        width: 100%;
        margin-top: 32px;
      }
    }
  }
}

.selcted-interset-chips {
  display: flex;
  gap: 8px;
  margin-top: 4px;
  flex-wrap: wrap;

  .intrest-chip {
    display: flex;
    padding: 8px 12px 8px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #f3f3f3;
    list-style: none;

    .chip-title {
      color: #25272d;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .cross-btn {
      cursor: pointer;
      margin-top: -4px;
    }
  }
}

.filter-selcted-title {
  margin-top: 32px;
  color: #828282;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.filter-result-title {
  margin-top: 46px;
  color: #25272d;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

// listing carousel
.carousel-container {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;

  .view-title-box-wrap {
    position: absolute;
    top: 0;
    width: 100%;

    .content {
      display: flex;
      justify-content: space-between;
      padding-right: 24px;
    }
  }
}

.plus-btn {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 1px 5px;
  justify-content: center;
  align-items: center;
  gap: 10.667px;
  flex-shrink: 0;
  border-radius: 173.333px;
  background: var(--Orange-Gradient,
      linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
  box-shadow: 0px 5.33333px 42.66667px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.carousel-cards-container {
  display: flex;
  overflow: hidden;
  padding-top: 70px;
}

.carousel-cards-inner {
  display: flex;
  transition: transform 0.5s ease;
}

@media screen and (max-width: "768px") {
  .carousel-cards-inner {
    overflow: scroll;
  }
}

.created-listing-wrapper {
  display: flex;

  scroll-behavior: smooth;
  /* Optional: smooth scrolling effect */
  padding: 0 12px;
  /* Optional: horizontal padding for spacing */
  gap: 24px;
  /* Space between cards */

  flex-wrap: nowrap;
  overflow-x: scroll;
  scrollbar-width: none;
  white-space: nowrap;

  .carousel-card {
    // flex: 0 0 calc(100% / 3.5);
    /* Create space for 3.5 cards */
    // max-width: 287px;
    // margin-right: 24px;
    height: 400px;
    width: 295px;
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    position: relative;

    .event_type_mylisting{
      position: absolute;
      top: 12px;
      left: 12px;
    }

    .list-type-wrapper {
      background: linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
      height: 28px;
      padding: 6px 16px 6px 12px;
      border-radius: 8px;
      border: none;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      position: absolute;
      top: 12px;
      left: 12px;
      display: flex;
      gap: 4px;
    }

    .distance-wrapper {
      background: #00000052;
      height: 28px;
      padding: 6px 16px 6px 12px;
      border-radius: 8px;
      border: none;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      position: absolute;
      top: 47px;
      left: 12px;
      display: flex;
      gap: 4px;
    }

    .price-wrapper {
      background: linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
      height: 28px;
      padding: 2px 6px 2px 6px;
      border-radius: 8px;
      border: none;
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      position: absolute;
      top: 190px;
      right: 12px;
      display: flex;
      gap: 4px;
    }

    // .slider-container {
    //   .slick-slider {
    //     display: flex;

    //     .slick-slide {
    //       width: fit-content !important;
    //       // padding-left: 16px;
    //       // padding-right: 16px;
    //     }

    //     .prev-arrow-btn-container {
    //       display: flex !important;
    //       align-items: center;
    //       // width: 10px;
    //       // padding-left: 16px;
    //       margin-right: 16px;
    //       cursor: pointer;

    //       /* Auto layout */
    //       .prev-arrow-btn {
    //         display: flex;
    //         flex-direction: row;
    //         justify-content: center;
    //         align-items: center;

    //         width: 32px;
    //         height: 32px;

    //         /* White */
    //         background: #ffffff;
    //         /* SecondarySteel */
    //         border: 1px solid #8f939a;
    //         border-radius: 28px;
    //         transform: matrix(-1, 0, 0, 1, 0, 0);

    //         img {
    //           width: 16px;
    //           height: 16px;
    //         }
    //       }
    //     }

    //     .next-arrow-btn-container {
    //       display: flex !important;
    //       align-items: center;
    //       margin-left: 24px;
    //       // width: 10px;
    //       cursor: pointer;

    //       /* Auto layout */
    //       .next-arrow-btn {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;

    //         width: 32px;
    //         height: 32px;

    //         /* White */
    //         background: #ffffff;
    //         /* SecondarySteel */
    //         border: 1px solid #8f939a;
    //         border-radius: 28px;
    //         transform: matrix(-1, 0, 0, 1, 0, 0);

    //         img {
    //           width: 16px;
    //           height: 16px;
    //         }
    //       }
    //     }
    //   }
    // }
    // .custom-slick-slider .slick-track {
    //   width: 295px !important; /* Set the desired width */
    // }
    .listing_box {
      border: 1px solid #f2f2f2;
      border-radius: 12px;
      position: relative;
      width: 287px;

      .videoicon {
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        top: 65px;
      }

      .tag {
        position: absolute;
        left: -7px;
        top: 24px;
      }

      .event_img {
        height: 160px;
        width: 100%;
        border-radius: 12px 12px 0 0;
        object-fit: cover;
      }

      .span_box {
        position: relative;

        .distance {
          background: #00000052;
          padding: 4px 16px 4px 16px;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: right;
          color: #fff;
          position: absolute;
          top: -20px;
          left: 12px;
        }

        .price {
          background: linear-gradient(167.07deg,
              #ee7830 -15.49%,
              #ee762f 149.41%);
          border-radius: 8px;
          padding: 4px 16px 4px 16px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          color: #fff;
          position: absolute;
          right: 12px;
          top: -20px;
        }
      }

      .title {
        display: inline-block;
        width: 200px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 12px;
        min-height: 40px;
        margin-top: 16px;
      }

      .others {
        display: flex;
        justify-content: space-between;
        padding: 0 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;

        .time {
          display: flex;
          align-items: center;
          gap: 4px;
          color: #8f939a;
        }

        .remaining {
          display: flex;
          align-items: center;
          gap: 2px;
          color: #8f939a;
        }
      }

      .total-session {
        margin-top: 8px;
        padding-left: 16px;
        color: var(--SteelBlue, #25272d);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }

      .name {
        background: #f3f3f3;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        padding: 10px 12px;
        margin-top: 14px;
        border-radius: 0 0 12px 12px;
        display: flex;
        align-items: center;
        gap: 2px;

        span {
          white-space: nowrap;
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }

        img,
        video {
          width: 20px;
          height: 20px;
          object-fit: cover;
          margin-right: 5px;
          vertical-align: middle;
          border-radius: 100%;
          box-shadow: 0px 3.3333332538604736px 10px 0px #0000001f;
        }
      }
    }

    .activeList {
      // margin-top: 16px;
      border-radius: 0px 0px 12px 12px;
      background: #f1fff7;
      color: #40b67d;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      position: absolute;
      bottom: 0px;
      right: 0px;
      left: 0px;
      width: 293px;
    }

    .inactiveList {
      // margin-top: 16px;
      padding: 10px;
      border-radius: 0px 0px 12px 12px;
      background: #ffe2e2;
      color: #eb5757;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      position: absolute;
      bottom: 0px;
      right: 0px;
      left: 0px;
      width: 293px;
    }

    .more-icon-item {
      display: flex;
      align-items: center;
      color: #25272d;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
      gap: 32px;
      margin-bottom: 16px;
      cursor: pointer;
    }

    .card-right-buttons-wrap{
      position: absolute;
      top: 12px;
      right: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3px;
      .more-popup-listing{
        position: unset;
        width: 32px;
        height: 32px;
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .carousel-card {
    .listing_box {
      width: 257px;
      margin-top: 1rem;
    }
  }
}

.carousel-button {
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
}

.prev-button {
  right: 264px;
}

.next-button {
  right: 224px;
}

.pass-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #8f939a;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-bottom: 36px;
  max-width: 555px;
}

@media screen and (max-width: "768px") {
  .pass-sub-title {
    color: var(--SecondarySteel, #8f939a);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 36px;
  }
}

/* OutlineButton.css */
.outline-button-yellow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 20px;
  border-radius: 10px;
  border: 1px solid #f3f3f3;
  color: #ee7830;
  background: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: relative;
  width: 100%;
}

.sidebar-modal {
  justify-content: flex-end !important;

  .modal-section-sidebar {
    height: 100% !important;
    border-radius: 0 !important;
    max-width: 610px !important;
    display: block !important;

    .modal-sidebar-heading-box {
      padding-top: 60px;
      padding-bottom: 42px;
      color: var(--SteelBlue, #25272d);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 0;
      display: flex;
      align-items: center;
      gap: 12px;
      padding-left: 24px;
    }

    .new-top-sidebar {
      overflow: unset !important;
    }
  }
}

@media screen and (max-width: "768px") {
  .sidebar-modal {
    .modal-section-sidebar {
      .modal-sidebar-heading-box {
        padding-top: 16px;
        padding-left: 0px;
      }
    }
  }
}

.save_btn {
  position: absolute;
  right: 24px;
  max-width: 160px;
  border-radius: 130px;
  bottom: 100px;
}

.event-card-wrap {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #f3f3f3;
  background: #fff;
  max-width: 647px;
  margin: 0 auto;
  margin-bottom: 24px;
  cursor: pointer;

  .content-box {
    display: flex;
    gap: 25px;
    position: relative;

    .content {
      width: 100%;
      padding-right: 48px;

      .title {
        color: #25272d;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .sub-content {
        align-items: center;
        display: flex;
        gap: 4px;
        color: #8f939a;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 10px;
      }
    }

    .delete-icon {
      position: absolute;
      right: 8px;
      top: 4px;
      cursor: pointer;
    }

    .edit-icon {
      position: absolute;
      right: 48px;
      top: 4px;
      cursor: pointer;
    }
  }

  .divider {
    border-bottom: 1px solid #f3f3f3;
  }
}

.no-result-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.popup-subhead {
  color: #8f939a;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 5px;
  margin-bottom: 24px;
  max-width: 314px;
}

//events dates Modal

.emodal_wrapper {

  .ex-title {
    color: var(--SteelBlue, #25272d);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28.5px;
  }

  .ex-sub-title {
    color: var(--SecondarySteel, #8f939a);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 32px;
    margin-top: 16px;
  }

  .eheading {
    .e_head_txt {
      color: var(--SteelBlue, #25272d);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
    }

    .ex-icon {
      padding: 16px;
      background-color: rgba(238, 120, 48, 0.1);
      border-radius: 100%;
    }

    .e_subhead_txt {
      color: var(--SecondarySteel, #8f939a);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .eclose_btn {
      right: 0px;
      top: 0px;
      cursor: pointer;
    }
  }

  .edates_wrapper {
    padding: 3rem;
    padding-top: 24px;

    .em_title {
      color: var(--SteelBlue, #25272d);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .ed_textarea {
      border-radius: 10px;
      border: 1px solid var(--FitnessNonSelected, #8f939a);
      background: #fff;

      label {
        color: var(--SteelBlue, #25272d);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }

      textarea.form-control:focus {
        box-shadow: 0 0 0 0 !important;
      }
    }

    .cancel_r_wrapper {
      .form-check-input:checked {
        background-color: #ee762f;
        border-color: #ee762f;
        box-shadow: #ee762f;
      }

      .form-check-input:focus {
        box-shadow: 0 0 0 0;
      }

      .cancel_r {
        p {
          color: var(--SteelBlue, #25272d);
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        input[type="radio"] {
          appearance: none;
          width: 20px;
          height: 20px;
          border: 2px solid #555555;
          border-radius: 50%;
          background-clip: content-box;
          padding: 3px;
        }

        input[type="radio"]:checked {
          background-color: #ee762f;
          border: 2px solid #ee762f;
        }
      }
    }

    .ed_textbox {
      display: flex;
      align-items: center;
      gap: 10px;

      .form-check-input:checked {
        background-color: #ee762f;
        border-color: #ee762f;
        box-shadow: #ee762f;
      }

      .form-check-input:focus {
        box-shadow: 0 0 0 0;
      }

      p {
        color: var(--SecondarySteel, #8f939a);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        span {
          color: #ee7830;
        }
      }

      input {
        width: 20px;
        height: 20px;
        accent-color: #ee7830;
      }
    }

    .c_b_title {
      text-align: end;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      text-decoration-line: underline;
      color: #ee7830;
    }

    .e_date_calendar {
      position: relative;

      .react-datepicker__day--highlighted-custom-1 {
        color: black;
        background-color: #fce4d6;
      }

      .rmdp-week {
        border-radius: 8px;
        overflow: hidden;

        .rmdp-day.rmdp-disabled:first-child {
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          overflow: hidden !important;
        }

        .rmdp-day.rmdp-disabled:last-child {
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          overflow: hidden !important;
        }

        .rmdp-day:first-child {
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          overflow: hidden !important;
        }

        .rmdp-day:last-child {
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          overflow: hidden !important;
        }

        .rmdp-day {
          margin: 5px 0px;
          border: 0px;

          span {
            border-radius: 0px !important;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
          }
        }

        .rmdp-day.rmdp-selected span {
          border-radius: 0px !important;

          &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background-image: url(../img/white-tick.svg) !important;
            background-repeat: no-repeat !important;
            background-position: 95% 7% !important;
            right: 3px;
            top: 2px;
          }
        }

        .rmdp-day.rmdp-disabled {
          border-radius: 0px !important;
        }
      }

      .calendar-info-cont {
        position: absolute;
        bottom: 0px;
        right: 0px;

        .c-info-cont {
          display: flex;
          gap: 14px;
          align-items: center;
          margin-bottom: 14px;

          .c-a-info {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
          }

          .c-color-1 {
            width: 17px;
            height: 17px;
            border-radius: 4px;
            background: #fce4d6;
          }

          .c-color-2 {
            width: 17px;
            height: 17px;
            border-radius: 4px;
            background: #ef7830;
          }

          .c-color-3 {
            width: 17px;
            height: 17px;
            border-radius: 4px;
            background: #f3f3f3;
          }
        }
      }
    }

    .e_all_dates_container {
      .em-date {
        display: flex;
        justify-content: space-between;
        border-radius: 12px;
        border: 2px solid #dddddd;
        padding: 1rem;
        flex-grow: 1;

        .em_date_first {
          .c_b_time {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }

          .em_actual_date {
            color: var(--SteelBlue, #25272d);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            display: flex;
            gap: 10px;
            align-items: center;

            .c_b_date {
              text-decoration: underline;
              color: #ee7830;
            }

            .em_b_full {
              display: inline-flex;
              padding: 6px 10px;
              align-items: flex-start;
              gap: 24px;
              border-radius: 100px;
              border: 1px solid rgba(37, 39, 45, 0.08);
              background: rgba(37, 39, 45, 0.12);
              margin-left: 60px;
              font-size: 13px;
            }
          }

          .em_slots {
            color: var(--SecondarySteel, #8f939a);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            align-self: end;

            span {
              color: #25272d;
            }
          }
        }

        .em_whislist {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          align-self: end;
          background: var(--Orange-Gradient,
              linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: absolute;
          right: 16px;
        }

        .em-date-second {
          .c_b_price {
            color: var(--SteelBlue, #25272d);
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }

          .date_book_counter {
            display: flex;
            width: 82px;
            // padding: 4px 0px;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(243, 243, 243, 1);
            border-radius: 16px;
            gap: 10px;
            height: 28px;
          }

          .form-check-input:checked {
            background-color: #ee762f;
            border-color: #ee762f;
          }
        }
      }

      .em-date.active {
        border: 2px solid #ee7830;
      }

      .active-inactive-box {
        display: flex;
        align-items: center;
        justify-content: center;

        .item {
          display: flex;
          flex-direction: column;
          gap: 4px;
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          width: 115px;
          height: 36px;
        }

        .active {
          border-bottom: 2px solid #ee762f;
          font-size: 12px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          color: #ee762f;
        }

        .non-active {
          border-bottom: 2px solid #8f939a;
          color: #8f939a;
        }
      }

      .checked_cont {
        .form-check-input {
          border: 2px solid #ee7830;
          width: 20px;
          height: 20px;
          border-radius: 4px;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    .schedule_event_dates {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--FitnessNonSelected, #f3f3f3);
      padding-right: 16px;

      .ver-p4 {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .sch_txt {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #ee762f;
      }
    }
  }

  .fl-heading {
    .fl-head-txt {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      text-align: center;
      margin-bottom: 32px;
    }

    .f-l-title {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }

    .f-l-subtitle {
      color: #8f939a;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .flag-listing-wrapper {
    .form-check-input:checked {
      background-color: #ee762f;
      border-color: #ee762f;
      box-shadow: #ee762f;
    }

    .form-check-input:focus {
      box-shadow: 0 0 0 0;
    }

    .flag-listing {
      p {
        color: var(--SteelBlue, #25272d);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      input[type="radio"] {
        appearance: none;
        width: 20px;
        height: 20px;
        border: 2px solid #555555;
        border-radius: 50%;
        background-clip: content-box;
        padding: 3px;
      }

      input[type="radio"]:checked {
        background-color: #ee762f;
        border: 2px solid #ee762f;
      }
    }
  }

  .fl-textarea {
    border-radius: 10px;
    border: 1px solid var(--FitnessNonSelected, #8f939a);
    background: #fff;

    label {
      color: var(--SteelBlue, #25272d);
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }

    .fl-words {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }

    textarea.form-control:focus {
      box-shadow: 0 0 0 0 !important;
    }
  }

  .ud_cont {
    display: flex;
    width: 269px;
    padding: 16px 44px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: var(--Orange-Gradient,
        linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
    color: white;
    cursor: pointer;
    font-weight: 500;
  }

  .ud_cont_gray {
    display: flex;
    width: 269px;
    padding: 16px 44px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: #f3f3f3;
    color: #8f939a;
    cursor: pointer;
    font-weight: 500;
  }

  .ud_cont_full {
    display: flex;
    width: 100%;
    padding: 16px 44px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: var(--Orange-Gradient,
        linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
    color: white;
    cursor: pointer;
  }

  .gender_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto;
    position: relative;
    gap: 20px;

    .gender_option {
      position: relative;
      display: flex;
      align-items: center;
      background: #f6f6f7;
      border-radius: 12px;
      padding: 12px;
      margin-right: 16px;
      cursor: pointer;

      gap: 10px;
      flex-grow: 1;

      .form_group {
        margin-bottom: 0;
      }

      .cross-icon {
        display: none;
      }

      &:last-child {
        margin-right: 0px;
      }

      .input_box {
        position: initial;
      }

      >img {
        width: 32px;
        height: 32px;
      }

      .input_box {
        textarea {
          // border: 1px solid #8f939a;
          border: 1px solid #dcdcdc;
          border-radius: 10px;
        }

        input {
          position: absolute;
          opacity: 0;

          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        input[type="radio"] {
          cursor: pointer;
        }

        .input_label {
          position: static;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          display: block;
          padding: 0 10px;
          white-space: nowrap;
        }
      }

      .check-icon {
        display: none;
        opacity: 0;
        width: 24px;
        height: 24px;
        background: url(../img/checked.svg);
        background-size: contain;
        // position: absolute;
        right: 8px;
        z-index: -1;
      }
    }

    .text-danger {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }

    .gender_option.active {
      padding: 12px;
      background: linear-gradient(167.07deg,
          rgba(238, 120, 48, 0.12) -15.49%,
          rgba(238, 118, 47, 0.12) 149.41%);

      .check-icon {
        opacity: 1;
        z-index: 2;
      }
    }
  }

  .ep_user_content {
    .eu_age_gender {
      justify-content: space-between;
      gap: 1rem;

      .up_age,
      .up_gender {
        flex-grow: 1;

        select {
          width: 100%;
        }
      }
    }

    .ep_input {
      border-radius: 10px;
      border: 1px solid #dddddd;
      background: #fff;
      padding: 10px;
      margin-bottom: 16px;

      .ep_input_label {
        color: var(--SteelBlue, #25272d);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }

      input {
        width: 100%;
        border: none;
        padding: 0px;
        margin-bottom: 0px;
      }
    }
  }

  .wl-title {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }

  .wl-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #8f939a;
  }
}


      .package-new-card {
        display: flex;
        justify-content: space-between;
        border-radius: 12px;
        border: 2px solid #dddddd;
        padding: 1rem;
        flex-grow: unset !important;

        .em_date_first {
          .c_b_time {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }

          .em_actual_date {
            color: var(--SteelBlue, #25272d);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            display: flex;
            gap: 10px;
            align-items: center;

            .c_b_date {
              text-decoration: underline;
              color: #ee7830;
            }

            .em_b_full {
              display: inline-flex;
              padding: 6px 10px;
              align-items: flex-start;
              gap: 24px;
              border-radius: 100px;
              border: 1px solid rgba(37, 39, 45, 0.08);
              background: rgba(37, 39, 45, 0.12);
              margin-left: 60px;
              font-size: 13px;
            }
          }

          .em_slots {
            color: var(--SecondarySteel, #8f939a);
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            align-self: end;

            span {
              color: #25272d;
            }
          }
        }

        .em_whislist {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          align-self: end;
          background: var(--Orange-Gradient,
              linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: absolute;
          right: 16px;
        }

        .em-date-second {
          .c_b_price {
            color: var(--SteelBlue, #25272d);
            text-align: right;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }

          .date_book_counter {
            display: flex;
            width: 82px;
            // padding: 4px 0px;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(243, 243, 243, 1);
            border-radius: 16px;
            gap: 10px;
            height: 28px;
          }

          .form-check-input:checked {
            background-color: #ee762f;
            border-color: #ee762f;
          }
        }
      }

//responsive dashboard fiters
@media screen and (max-width: "478px") {
  .sidebar-map {
    display: flex;
    max-width: calc(100vw - 32px);
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: "478px") {
  .fixed-sidebar-left .sidebar-box {
    .inner-contet {
      margin-top: 24px;
      max-width: 100vw !important;
    }

    ul li {
      max-width: 143px;
    }
  }

  .fixed-sidebar-right .sidebar-box {
    ul li {
      max-width: 143px !important;
    }
  }
}

@media screen and (max-width: "769px") {
  .maps-container {
    width: 100% !important;
    height: 390px !important;
    border-radius: 0px !important;
  }
}

.filter-popup-title {
  color: #25272d;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-left: 25%;
}

@media screen and (min-width: "768px") {
  .hide-sm {
    display: none;
  }
}

@media screen and (max-width: "767px") {
  .hide-large {
    display: none;
  }
}

.continue_btn_filter_popup {
  position: sticky;
  bottom: 24px;
  padding: 20px 0px;
  background: #fff;
  z-index: 12;

  &::before {
    content: "";
    position: absolute;

    width: 100%;
    height: 110px;
    bottom: 30px;
    left: 0;
  }

  button {
    position: relative;
  }
}

.btn_filter_popup_interest {
  position: fixed;
  bottom: 0;
  z-index: 9;
  width: 100%;
  padding: 20px;
  max-width: 650px;
  background: #fff;
}

.apply_btn_container {
  display: flex;

  .interest_chip_inner {
    display: flex;
    align-items: center;
    list-style: none;
    height: 30px;
    padding: 7px;
    gap: 6px;
    border-radius: 16px;
    border: 1px solid #ee7830;

    .chip_text {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color: #25272d;
    }
  }

  .chip_container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: "768px") {

  .fixed-sidebar-right,
  .fixed-sidebar-left,
  .fixed-sidebar {
    margin-top: 76px !important;
  }

  .continue_btn_filter_popup_new {
    bottom: 48px;
    margin-top: 24px;
  }
}

// .one-time {
//   .rmdp-shadow {
//     position: absolute;
//     top: -220px;
//     left: 60px;
//   }
// }

// .recurence {
//   .rmdp-shadow {
//     position: absolute;
//     top: -330px;
//     left: 60px;
//   }
// }

.start-date-recurence {
  .react-datepicker-popper {
    inset: -250px auto auto 75px !important;
  }
}

.end-date-recurence {
  .react-datepicker-popper {
    inset: -250px auto auto -230px !important;
  }
}

.width-734 {
  max-width: 734px !important;
}

.width-619 {
  max-width: 619px !important;
  transition: none !important;

  .for-responsive-only {
    .res-global-header {
      display: none;
    }
  }
}

.width-modal-390 {
  max-width: 390px !important;

  .emodal_wrapper {
    width: 100%;
    min-height: unset;
  }
}

@media screen and (max-width: 578px) {
  .emodal_wrapper {
    width: 100%;
    // min-height: 100vh;
  }

  .width-modal-390 {
    margin: auto !important;
  }

  .width-734 {
    width: 100%;
    margin: 0 !important;
    background-color: white;
    max-width: 734px !important;
  }

  .width-619 {
    width: 100%;
    margin: 0 !important;
    background-color: white;
    max-width: 619px !important;

    .for-responsive-only {
      margin: -1rem;

      .res-global-header {
        display: block;
        margin-bottom: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .rmdp-wrapper {
    margin: auto;
  }

  .emodal_wrapper {
    width: 100%;
    text-align: center;
    // min-height: 100vh;

    .edates_wrapper {
      padding: 0px;

      .rmdp-shadow {
        box-shadow: 0px 0px 0px 0px;

        .rmdp-calendar {
          padding: 0px;
        }
      }

      .e_date_calendar {
        .calendar-info-cont {
          position: unset;
          width: 70%;
          margin: auto;
        }
      }
    }

    .ep_user_content {
      .eu_age_gender {
        flex-direction: column;
        gap: 0;
      }
    }

    .fl-heading {
      .fl-head-txt {
        font-size: 20px;
      }
    }
  }
}

.promotion-list-wrap {
  background: #f9f9f9;
  padding-top: 27px;
  margin-top: 94px;

  .content-box {
    max-width: 1112px;
    border-radius: 12px;
    margin: 0 auto;
    padding: 32px;
    background: var(--FitnessWhite, #fff);
    min-height: calc(100vh - 121px);

    .content-title {
      color: var(--SteelBlue, #25272d);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      padding-left: 42px;
    }

    .content-subtitle {
      color: #535a67;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding-left: 42px;
    }

    .card-list-wrap {
      padding: 40px 42px;

      .listings {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        padding: 24px 0;

        .listing_box {
          border: 1px solid #f2f2f2;
          border-radius: 12px;
          position: relative;

          .videoicon {
            position: absolute;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
            top: 65px;
          }

          .event_img {
            height: 150px;
            width: 100%;
            border-radius: 12px 12px 0 0;
            object-fit: cover;
          }

          .title {
            width: 200px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            padding-left: 12px;
            min-height: 40px;
            margin-top: 16px;
            margin-bottom: 14px;
          }

          .others {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px;
            color: var(--SecondarySteel, #8f939a);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;

            .time {
              display: flex;
              align-items: center;
              gap: 4px;
              color: #8f939a;
            }
          }

          .desc {
            width: 286px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-left: 16px;
            padding-right: 29px;
            padding-top: 16px;
            margin-bottom: 50px;
          }

          .find-out {
            position: absolute;
            bottom: 16px;
            margin-left: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            background: var(--Orange-Gradient,
                linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .promo-link-title {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
      }
    }
  }

  // Promotion Details page
  .details-box-wrap {
    max-width: 1112px;
    margin: 0 auto;
    min-height: calc(100vh - 121px);

    .details-box {
      padding: 32px;
      background: #fff;
      border-radius: 12px;
      display: flex;
      gap: 24px;
      flex-wrap: wrap;

      .page_loader {
        margin: 0 auto;
      }

      .promocard-img {
        height: 428px;
        width: 390px;
        border-radius: 12px;
        object-fit: cover;
      }

      .detail-title {
        color: #25272d;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 16px;
      }

      .right-content {
        max-width: 561px;
        padding-top: 48px;
      }

      .others {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #8f939a;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;

        .time {
          display: flex;
          align-items: center;
          gap: 4px;
          color: #8f939a;
        }
      }

      .desc {
        margin-top: 24px;
        color: #25272d;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .btn-box {
        margin-top: 24px;
        width: 302px;
      }
    }
  }

  .no-promotion-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    flex-direction: column;

    .no-promotion-title {
      margin-top: 16px;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
      color: #8f939a;
    }

    .no-promotion-desc {
      margin-top: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      max-width: 500px;
      color: #8f939a;
    }
  }

  @media screen and (max-width: "768px") {
    .content-box {
      min-height: 100vh;
      padding: 16px;

      .card-list-wrap {
        padding: 0px;

        .listings {
          display: grid;
          padding: 24px 0;
          grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
          gap: 20px;
        }
      }

      .content-title,
      .content-subtitle {
        padding-left: 0px;
      }
    }

    .promocard-img {
      width: 100% !important;
    }
  }
}

//Listing page start
.earning-box-wrap {
  border-radius: 12px;
  background: #f3f3f3;
  padding: 18px 20px;

  .earning-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    color: #ee762f;
    margin-bottom: 22px;
  }

  .amount_earning {
    padding: 10px 12px;
    background: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #ee762f;
    margin-bottom: 12px;
    border-radius: 10px;
  }

  .cost {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #ee762f;
  }

  .info {
    color: var(--SteelBlue, #25272d);
    font-size: 12px;

    font-weight: 400;
    line-height: 16px;
    margin-top: 6px;

    span {
      font-weight: 500;
      margin-right: 2px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #25272d;
    }
  }

  .link_terms {
    color: #ee7830;
    cursor: pointer;
    font-weight: 600;
  }

  .info_main {
    color: var(--SteelBlue, #25272d);
    font-size: 12px;
    display: flex;
    align-items: flex-start;
    gap: 5px;
    font-weight: 400;
    line-height: 16px;

    span {
      font-weight: 500;
      margin-right: 2px;

      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      color: #25272d;
    }
  }

  .pay-table {
    border: 1px solid #8f939a;
    width: 100%;
    margin: 16px 0px;

    .pay-t-header {
      background-color: #d9d9d9;

      th {
        padding: 15px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #000000;
        text-align: center;
      }

      th:first-child {
        border-right: 2px solid #8f939a;
      }
    }

    td:first-child {
      border-right: 2px solid #8f939a;
    }

    td {
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      padding: 4px;
    }
  }

  .terms-info {
    color: var(--SteelBlue, #25272d);
    font-size: 12px;

    font-weight: 400;
    line-height: 16px;
    margin-top: 12px;

    .terms-link {
      display: flex;
      color: #ee762f;
    }
  }

  .divider {
    height: 1px;
    opacity: 0.18;
    background: #8f939a;
    margin: 12px 0px;
  }

  .fee-box {
    margin-top: 12px;
    display: flex;
    gap: 80px;

    .fee-title {
      color: var(--SteelBlue, #25272d);
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
    }

    .desc {
      color: var(--SteelBlue, #25272d);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.my-earning-table{
  background: none;
  width: 550px;
  padding: 0;
  @media screen and (max-width: 768px) {
    width: fit-content;
  }

  .pay-table{
    border-color: #DCDCDC;
    .pay-t-header {
      th{
        background: #F3F3F3;
      }
      th:first-child{
        border-color: #DCDCDC;
      }
    }
    td:first-child{
      border-color: #DCDCDC;
    }
  }
}

.earning-composition-wrap{
  width: 570px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  .earnings-amount-wrap{
    grid-template-columns: 4fr 1fr;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 0fr;
    }
  }
}


  .earnings-amount-wrap{
    display: grid !important;
    grid-template-columns: 3fr 1fr;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 0.7fr;
    }
  }

.booking-chip {
  width: 195px;
  display: flex;
  padding: 4px 8px 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--Gray-5, #e0e0e0);
  color: var(--SteelBlue, #25272d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  span {
    cursor: pointer;
  }
}

.select-box-booking {
  position: relative;

  .input_label {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    left: 14px;
    color: #25272d;
    top: 10px;
    z-index: 2;
  }

  .clear-icon {
    position: absolute;
    right: 12px;
    top: 20px;
  }

  .select-wrap {
    width: 100%;
    padding: 26px 12px 8px !important;
    padding-right: 45px !important;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    background: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #25272d;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: "";
  }
}

.view-all-box {
  color: var(--SteelBlue, #25272d);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
  text-align: right;
  cursor: pointer;
  padding-right: 24px;
  padding-bottom: 12px;
}

//Listing page end

//custom days wrap start
.data-length-wrapper {
  width: 100%;
  display: inline-block;
  padding: 0;
  border: 0;

  .outer-container {
    position: relative;
    display: inline-block;
    width: 100%;

    .content-box {
      width: 100%;
      padding: 26px 12px 8px;
      padding-right: 45px;
      border: 1px solid #dcd9d9;
      border-radius: 10px;
      background: transparent;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #25272d;
      margin-top: -62px;
      height: 58px;
    }
  }
}

//custom days wrap end

//Radio Select
.radio-select {
  display: flex;
  flex-direction: row;
  padding: 4px 0px;

  input[type="radio"] {
    /* Hide the default appearance */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* Create a custom radio button */
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #212121;
    /* Set the unchecked border color */
    background-color: transparent;
    /* Set the unchecked background color */
    /* Position the checkmark in the center */
    position: relative;
    margin-right: 5px;
  }

  /* Style the checkmark inside the custom radio button */
  input[type="radio"]:checked::before {
    content: "";
    width: 10px;
    height: 10px;
    border: 1px solid #ee7830;
    background-color: #ee7830;
    /* Set the checked background color */
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* Add additional styling if needed */

  .radio-value {
    margin-left: 10px;
  }
}

.toggle {
  width: 50.38px;
  height: 26px;
  border: 1px solid #ee7830;
  border-radius: 20px;
  background: #f3f3f3;
  position: relative;
}

.switch {
  height: 21.12px;
  width: 21.12px;
  background: #ee7830;
  border-radius: 100%;
  position: absolute;
  top: 1.6px !important;
  left: 1px !important;
}

.switch-right {
  height: 21.12px;
  width: 21.12px;
  background: #ee7830;
  border-radius: 100%;
  position: absolute;
  top: 1.6px !important;
  right: 1px !important;

}

.toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
}

.subscription-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;

  .plan-details {
    position: relative;
    width: 80%;
    // height: 172px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ee78301f;
    border: 1px solid #ee7830;
    margin-top: 40px;

    .plan-name {
      font-size: 25px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: #25272d;

      @media screen and (max-width:'600px') {
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
      }
    }

    .plan-value {
      font-size: 38px;
      font-weight: 600;
      line-height: 34px;
      text-align: center;
      color: #ee7830;
      margin-top: 12px;
      margin-bottom: 8px;

      @media screen and (max-width:'600px') {
        font-size: 32px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
      }
    }

    .plan-value-gray {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: #8f939a;

      @media screen and (max-width:'600px') {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
      }


    }
  }
}

.discount-label {
  background-color: #ee7830;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 32px;
  border-radius: 9px;
  color: #fff;
  // margin-bottom: -16px;
  z-index: 1;
  position: absolute;
  top: -16px;
  left: calc(50% - 85px);
}

.gray-note {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  margin-top: 22px;
  color: #25272d;

  span {
    color: #8f939a;
    font-weight: 400;
  }
}

@media screen and (max-width:"768px") {
  .gray-note {
    margin-bottom: 40px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18.5px;
    color: #6c6d6f;
  }
}

.loader {
  height: 100%;
  width: 100%;
  backdrop-filter: blur(6px);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.button-option {
  position: absolute;
  height: 45px;
  top: 50px;
  background-color: #f2f2f2;
  padding: 12px 8px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
}

.round-button {
  height: 44px;
  width: 44px;
  border-radius: 100%;
  border: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: end;
  cursor: pointer;
}

.unchecked-checkbox {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border: 1px Solid #8f939a;
}

.checked-checkbox {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: #ee7830;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checked-checkbox {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: #ee7830;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancellation-policy {
  display: flex;
  flex-direction: column;
  color: #25272d;
  font-weight: 500;
  padding: 16px 0px;
  width: 100%;
  border-top: 2px Solid #f1f1f1;
  margin-top: 20px;

  .sub-text {
    color: #25272d;
    font-size: 14px;
    font-size: 14px;
    font-weight: 400;
  }
}

.stripe-connect-wrapper {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  max-width: 555px;

  .wl-title {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }

  .wl-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #8f939a;
  }
}

.infoguestlisting {
  // position: absolute;
  // top: 54px;
  // left: 50%;
  margin-bottom: 24px;

  .dl-email-verified_listing {
    padding: 12px;
    background-color: #8f939a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    gap: 12px;

    .dl-title {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .dl-subtitle {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-decoration-line: underline;
    }
  }

  .verifyEmailAlert {
    background-color: #EE7830;
  }

  @media screen and (max-width:"768px") {
    top: -40px;
    // width: calc(100% - 32px);
  }
}

.emailAlertWrap{
  width: 450px;
  margin: auto;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.recent-location-wrapper {
  margin-left: 16px;

  .recent-text {
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #828282;
  }

  .recent-location {
    display: flex;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 8px;
    margin-bottom: 8px;

    .recent-1 {
      flex-grow: 1;
    }

    .recent-2 {
      flex-grow: 8;

      .recent-2-1 {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #25272d;
      }

      .recent-2-2 {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #8f939a;
      }
    }

    .recent-3 {
      flex-grow: 1;
    }
  }
}

.selected-recent-loc {
  .recent-img {
    width: 100%;
  }

  .recent-full-loc {
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 4px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #8f939a;
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.location_listing {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 8px;

  .loc_sel_icon {
    // margin-bottom: 24px;
    cursor: pointer;
    width: 50px;
  }
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: white !important;
  background-color: #ee7830 !important;
  border-color: #ee7830 !important;
  border-radius: 5px;
  font-weight: 600 !important;
  font-size: 16px;
}

// .page-item {
//   margin-top: 10px;
// }

.page-item a {
  color: #8f939a !important;
  background: none !important;
  border: none !important;
  font-size: 16px;
  margin-top: 3px;
}

.page-link:first-child {
  padding-top: 5px !important;
}

.page-link:last-child {
  padding-top: 5px !important;
}

.active-text {
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: #ee762f !important;
}

.active-text-new {
  color: #ee762f !important;
}

.no-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 20px;
}

.pac-container {
  background-color: #fff;
  z-index: 2001;
  position: fixed;
  display: inline-block;
  float: left;
}

.modal {
  z-index: 2000;
}

.modal-backdrop {
  z-index: 1000;
}

.special-listing-event {
  display: flex;
  width: 100%;
  gap: 15px;
  align-items: center;
  background-color: rgba(238, 120, 48, 0.1);
  padding: 0px 18px;
  border-radius: 12px;
  margin-top: 16px;
}

.special-listing-event .icon {
  background-color: #ee762f;
  border-radius: 100%;
  padding: 4px;
  display: flex;
  margin-top: 10px;
}

.page-link.disabled,
.disabled>.page-link {
  background: none !important;
  border: 0px !important;
}

.pagination .disabled {
  background: none !important;
}

.pagination .disabled .previous-button {
  color: lightgray !important;
}

.previous-button {
  color: #25272d;
  font-weight: 400;
}

.previous-button:hover {
  color: #ee7830;
}

.pagination>li.active>a {
  border: 0px !important;
}

.page-item:not(:first-child):not(:last-child) .page-link {
  padding-top: 7px !important;
}

.page-item .active {
  border: 0px !important;
  box-shadow: none !important;
}

.page-link {
  box-shadow: none !important;
}

.loader_stripe {
  border: 5px solid rgba(238, 120, 48, 0.1);
  border-radius: 50%;
  border-top: 5px solid #ee762f;
  width: 60px;
  height: 60px;
  -webkit-animation: spin_stripe 2s linear infinite;
  animation: spin_stripe 2s linear infinite;
}

@-webkit-keyframes spin_stripe {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin_stripe {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: "768px") {
  .listing-type-mobile {
    padding-top: 20px !important;
  }

  .em_b_full {
    display: inline-flex;
    padding: 6px 10px;
    align-items: flex-start;
    gap: 24px;
    border-radius: 100px;
    border: 1px solid rgba(37, 39, 45, 0.08);
    background: rgba(37, 39, 45, 0.12);
    margin-left: 100px !important;
    font-size: 13px;
  }
}

.input_box textarea:disabled {
  background: #e0e0e0;
}

.pad-class .input_box input {
  padding-right: 75px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 12px;
  top: calc(50% - 12px);
  padding: 0;
  cursor: pointer;
}

.react-datepicker__month-container {
  box-shadow: 0px 4px 8px 0px #0000000f, 0px 0px 4px 0px #0000000a,
    0px 4px 8px 0px #0000000f, 0px 0px 4px 0px #0000000a;
  border-radius: 8px;
}

.react-datepicker--time-only {
  border: 1px solid #8f939a14;
  box-shadow: 0px 4px 8px 0px #0000000f, 0px 0px 4px 0px #0000000a,
    0px 4px 8px 0px #0000000f, 0px 0px 4px 0px #0000000a;
  border-radius: 12px;
  padding: 0px;
  max-width: 240px;
  box-sizing: border-box;
  background: #ffffff;

  .react-datepicker__time-container {
    width: 100% !important;
  }

  .react-datepicker__time-box {
    width: 230px !important;
    padding: 0px 12px;
  }

  .react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;

    &.react-datepicker__time-list-item--selected {
      background-color: #ed772e !important;
      color: #fff !important;
      border-radius: 4px;
    }
  }
}

.max-slots-done {
  cursor: not-allowed;
}

.create-listing-btn {
  z-index: 150;
}

.booking-full-css {
  display: inline-flex;
  padding: 6px 16px;
  align-items: flex-start;
  gap: 24px;
  border-radius: 100px;
  border: 1px solid rgba(37, 39, 45, 0.08);
  background: rgba(37, 39, 45, 0.12);
}

.crousel-container:hover {

  .dots-nav-container,
  .image-carousel-button {
    display: flex !important;
  }
}

.more-width {
  width: 200px !important;
}

.less-width {
  max-width: 111px !important;
}

@media screen and (max-width: "768px") {
  .max-width-model-set .modal-content {
    max-width: 100%;
    overflow-x: hidden;
  }

  .emodal_wrapper .edates_wrapper .e_all_dates_container .active-inactive-box .item {
    width: 50%;
  }

  .create-listing-btn {
    width: 90% !important;
    bottom: 10px !important;
  }

  .no-content {
    font-size: 16px;
  }

  .em-date {
    max-width: 100% !important;
    height: auto;
  }

  .page-event-booking .info-box {
    width: 346px;
    height: 68px;
  }

  .page-event-booking .detail-button-box .tab-button-active {
    padding: 16px 10px !important;
    height: 174px;
    height: 56px;
  }

  .page-event-booking .detail-button-box .tab-button {
    padding: 16px 10px !important;
    height: 174px;
    height: 56px;
  }

  .page-event-booking .save-attendies {
    text-align: center;
    // font-size: 14px !important;
  }

  .more-width {
    width: 60% !important;
    flex-direction: row !important;
    flex-basis: 60%;
  }

  .more-width .mobile-full-res {
    width: 100%;
  }

  .less-width {
    max-width: 40% !important;
    flex-direction: row !important;
    flex-basis: 40%;
  }

  .less-width .mobile-full-res {
    width: 100%;
  }

  .pagination-inside-slotting {
    margin: auto;
  }

  .active-inactive-box {
    flex-direction: row !important;
  }
}

.rmdp-input:focus {
  box-shadow: none;
}

.create-listing-btn {
  position: fixed;
  bottom: 90px;
  display: flex;
  justify-content: flex-end;
  right: 30px;
}

@media screen and (max-width: "768px") {
  .mobile-circle {
    height: 26px !important;
    width: 26px !important;
    font-size: 13.5px !important;
    font-weight: 400;
  }

  .step-text {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .step-text-one {
    width: 50px !important;
  }

  .step-text-bold {
    width: 50px !important;
    font-weight: 500 !important;
  }

  .step-text-two-bold {
    font-weight: 500 !important;
  }

  .step-text-three {
    justify-content: right;
    width: 47px;
  }

  .step-text-three-bold {
    font-weight: 500 !important;
    justify-content: center;
    width: 47px;
  }

  .event-booking-accordion .accordion-header {
    height: 40px;
  }

  .event-booking-accordion .accordion-header .accordion-button {
    height: 40px !important;
  }

  .save-attendies {
    margin-top: 4px !important;
    text-align: center !important;
  }
}


.custom_resp_position{
  width: 47px;
    height: 47px;
    background: #fff;
    position: fixed;
    z-index: 150 !important;
    bottom: 30px;
    right: 20px;
    border-radius: 50px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;

@media screen and (max-width: "768px") {
  bottom: 70px;
    right: 20px;

}

}

.ageFieldWrap{
  .customNumberInput {
    .label{
      top: 8px;
    }
    .base-NumberInput-root{
      padding: 6px 0px;
      width: auto;
      .base-NumberInput-input{
        padding: 18px 12px;
        padding-bottom: 1px;
      }
    }
  }
.map-link {
  color: #ee7830;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}
}