.Custom-week-wrap {
  .label {
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    color: #25272d;
    line-height: 24px;
  }
  .custom-weeklayset {
    display: flex;
    gap: 8px;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    @media screen and (max-width: 480px) {
      gap: 4px;
    }

    li {
      flex: 1;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #25272d;
      padding: 10px 3px;
      border: 1px solid #dcdcdc;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.active {
        background: linear-gradient(167.07deg, rgba(238, 120, 48, 0.12) -15.49%, rgba(238, 118, 47, 0.12) 149.41%),
          #ffffff;
        color: #ee762f;
        border: 1px solid #ee7831;
      }
      &.disabled {
        background: none;
        color: #dcdcdc;
      }
    }
  }
}
