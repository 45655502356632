.meeting_link_field_wrapper {
    border: 1px solid #DCDCDC;
    background: #FFFFFF;
    width: 100%;
    border-radius: 12px;
    display: flex;
    padding: 8px 26px;
    gap: 12px;

    .vertical_line {
        width: 2px;
        height: auto;
        background: #DCDCDC;

        @media screen and (max-width:600px) {
            display: none;
        }
    }

    .date_stamp_wrap {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px 0px;
        min-width: 300px;

        @media screen and (max-width:600px) {
            min-width: auto;
        }

        .order {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(238, 120, 48, 0.23);
            font-size: 75%;
            font-weight: 500;
            text-align: left;
            color: #25272D;
        }

        .time_date {
            display: flex;
            align-items: center;
            gap: 24px;

            .date_stamp {
                display: flex;
                align-items: center;
                gap: 6px;
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
                text-align: left;
                color: #25272D;
            }

            .time_stamp {
                display: flex;
                align-items: center;
                gap: 6px;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                text-align: left;
                color: #6C6D6F;
            }
        }
    }

    .link_box {
        flex-grow: 1;
        padding: 6px 0px;

        .link_box_wrap {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 4px;

            .input_container {
                flex-grow: 1;
                position: relative;


                .input_item {
                    width: 100%;
                    border: none;
                    outline: none;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: left;
                    color: #25272D;
                    padding: 0px 30px 0px 0px;
                }

                .clear_icon {
                    position: absolute;
                    right: 0;
                    top: calc(50% - 10px);
                    cursor: pointer;
                }


            }

            @media screen and (max-width:600px) {
                border-radius: 12px;
                border: 1px solid #DCDCDC;
                padding: 9px 16px;
            }
        }

    }

    @media screen and (max-width:600px) {
        border: none;
        background: transparent;
        width: 100%;
        flex-direction: column;
        padding: 0px;
        gap: 0px;
        border-radius: 0px;
        padding-bottom: 8px;
        border-bottom: 1px solid #DCDCDC;

        &:last-child {
            border-bottom: none;
        }
    }
}