.customMultiSelect-wrap {
    width: inherit;
    .MuiFormControl-root {
        width: 100%;
        .MuiOutlinedInput-notchedOutline {
            border-radius: 10px;
            border-color: #dcdcdc !important;
        }
        .MuiInputBase-root-MuiOutlinedInput-root:hover {
            border: 0;
        }
        .MuiOutlinedInput-input {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
        }
        .Mui-focused {
            color: #25272d;
        }
    }
}

.customMultiSelect-paper {
    .MuiTypography-root {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
    }
    .MuiCheckbox-root {
        color: #ee7830 !important;
    }
    .MuiMenuItem-root:hover {
        background-color: unset;
    }
    .Mui-selected:hover {
        background-color: unset !important;
    }
    .MuiMenuItem-root.Mui-selected {
        background-color: unset !important;
    }
}
