.WeeklyListingWrap {
    .repeatInEvery {
        .base-NumberInput-root {
            padding: 9px 0px;
        }
    }

    .end-event-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
    }

    .endByDate {
        .MuiOutlinedInput-root {
            padding-right: 0;
        }
        .MuiOutlinedInput-input {
            padding: 8.5px 14px;
        }
    }

    .monthDay {
        width: 170px;
        @media screen and (max-width: 575px) {
            width: 100%;
        }
    }

    .footer-note {
        background: #f3f3f3;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #6c6d6f;
        border-radius: 10px;
        padding: 10px 14px;
        width: max-content;
        @media screen and (max-width: 700px) {
            width: auto;
        }
    }
}
