.body_wrapper {
    display: flex;
    width: 100%;
    height: fit-content;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 8px 52px 58px 52px;

    @media screen and (max-width:786px) {
        padding: 8px 20px 12px 20px;
    }

    .success_gif {
        max-width: 238px;
        max-height: 238px;

        @media screen and (max-width:786px) {
            max-width: 185px;
            max-height: 185px;
        }
    }

    .payment_success_title {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        text-align: center;
        color: #25272D;
        margin-bottom: 24px;

        @media screen and (max-width:786px) {
            margin-bottom: 12px;
            font-size: 20px;
            line-height: 30px;
        }
    }

    .payment_success_sub_title {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #25272D;
        margin-bottom: 32px;

        @media screen and (max-width:786px) {
            margin-bottom: 20px;
        }
    }

    .close_btn_payment_success {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        max-width: 44px;
        max-height: 44px;
    }
}