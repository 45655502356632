.read_more_container {
    transition: all 0.3s ease-in-out;

    .read_more_btn {
        color: #25272D;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: #EE7830;
        }
    }

}