.card_inner_body {
    min-width: 150px;
    width: 100%;
    height: 89px;
    border: 1px solid #6C6D6F;
    border-radius: 12px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 389px) {
        min-width: calc(50% - 4px);
        width: 100%;
    }

    .date_card_data {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: #25272D;

    }

    .time_card_data {
        width: 100%;
        display: flex;
        gap: 6px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        color: #6C6D6F;

        .time_icon_preview {
            width: 16px;
            height: 16px;
        }
    }
}