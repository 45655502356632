.packageLimit-wrap{
    width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 700px) {
        width: 100%;
    }

    .customNumberInput {
        .base-NumberInput-input{
            line-height: 42px;
            width: 30px;
            padding-right: 0;
        }
        .base-NumberInput-decrementButton{
            height: 28px;

        }
        .base-NumberInput-incrementButton{
            height: 28px;

        }
        .base-NumberInput-root{
            // width: 120px;
        }
        .endAdornment{
            width: max-content;
        }
    }

    .custom-single-select{
        .input_label{
            top: -6px;
            background: white;
            padding: 0 2px;
        }
    }
}