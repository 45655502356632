.layout_map_view_card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    max-width: 100%;

    .map_view_card_wrap {
        display: flex;
        gap: 24px;
        width: fit-content;
        max-width: 100%;
        overflow-x: auto;
        padding-bottom: 10px;

    }
    .map_next_arrow{
        right: 45%;
        @media screen and (max-width: 480px) {
            right: 10%;
        }
    }

    .map_prev_arrow{
        left: 45%;
        @media screen and (max-width: 480px) {
            left: 10%;
        }
    }

    .map_view_card_wrap::-webkit-scrollbar {
        height: 8px;
        // height: 0;
    }

    .slick-slider{
        padding-bottom: 40px;
    }

    .slick-prev{
        left: 30%;
        // background: black;
        // filter: invert(1);
        z-index: 100;
        top: 100%;
        @media screen and (max-width: 480px) {
            top: 95%;
            left: 10%;
        }
    }
    .slick-next{
        right: 30%;
        // background: black;
        // filter: invert(1);
        z-index: 100;
        bottom: 0;
        top: 100%;
        @media screen and (max-width: 480px) {
            top: 95%;
            right: 10%;
        }
    }
    .slick-next:before{
        font-size: 50px;
        color: grey;
    }
    .slick-prev:before{
        font-size: 50px;
        color: grey;
    }

}