.info_container {
    width: 100%;
    padding: 10px 12px 10px 12px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px;
    background: #F3F3F3;

    .info_text {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #52545B;
    }

    img {
        width: 16px;
        height: 16px;
    }

}