.user_type_wrapper {
    background: #FFFFFF;
    position: relative;

    .free-trial-btn {
        max-width: 555px;
        display: flex;
        justify-content: center;
    }

    .background_left {
        position: absolute;
        top: 0;
        left: 0;

        @media screen and (max-width: 600px) {
            // display: none;
        }
    }

    .background_right {
        position: absolute;
        bottom: 0;
        right: 0;

        @media screen and (max-width: 600px) {
            // display: none;
        }
    }

    .user_container {
        z-index: 2;
        position: relative;



        .user_content {
            max-width: 1000px;
            padding: 30px 30px 30px 30px;
            margin: 0px auto;
            position: relative;

            @media screen and (max-width: 900px) {
                padding: 40px 20px;
            }

            .back_btn {
                position: absolute;
                top: 30px;
                left: -30px;
                display: flex;
                cursor: pointer;

                @media screen and (max-width: 1280px) {
                    left: -12px;
                }

                @media screen and (max-width: 900px) {
                    display: none;
                }
            }



            .main_heading {
                font-size: 36px;
                font-weight: 600;
                line-height: 36px;
                text-align: center;
                color: #EE7830;


                @media screen and (max-width: 900px) {
                    color: #25272D;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            .main_sub_heading {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                text-align: center;
                color: #8F939A;
                margin-top: 13px;
                margin-bottom: 30px;

                &.special {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 32px;

                    @media screen and (max-width: 900px) {
                        font-size: 14px;
                        line-height: 21px;

                    }
                }

                @media screen and (max-width: 900px) {
                    margin-bottom: 0px;
                    font-size: 14px;
                    line-height: 21px;

                }
            }

            .free-trial-heading {
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                text-align: center;
                color: #EE7830;
                margin-top: 0px;
                margin-bottom: 18px;

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                    line-height: 21px;

                }


            }
        }
    }

    .compare_table {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 45px;

        .compare_table_row {
            width: 100%;
            display: flex;
            z-index: 2;
            position: relative;
        }

        .compare_table_heading .compare_table_cell {
            padding: 28px 0px 16px 0px;
        }

        .horizontal_line {
            position: absolute;
            bottom: 0;
            width: 100%;
            border-top: 1px solid #F3F3F3;
        }

        .compare_table_row:last-child .horizontal_line {
            position: absolute;
            bottom: 0;
            width: 100%;
            border-top: 0px solid #F3F3F3;
        }

        .compare_table_cell {
            padding: 2px 0px;
        }

        .first_cell {
            width: calc(100% - 460px)
        }

        .compare_table_cell:not(.first_cell) {
            width: 230px;
            text-align: center;
        }

        .highlight {
            border-left: 1px solid #EE7830;
            border-right: 1px solid #EE7830;
            background: linear-gradient(177.62deg, rgba(238, 120, 48, 0.08) -3.39%, rgba(238, 118, 47, 0.08) 32.22%);
            z-index: 3;
            font-size: 16px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: -0.40799999237060547px;
            text-align: right;
            color: #25272D;
        }

        .guest_data {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #25272D;
        }

        .free {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            color: #25272D;
        }

        .compare_table_row .first_cell {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #8F939A;
        }

        .compare_table_row:first-child .highlight {
            border-top: 1px solid #EE7830;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        .compare_table_row:last-child .highlight {
            border-bottom: 1px solid #EE7830;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        .special {
            position: relative;

            .pop_style {
                width: 56px;
                height: 28px;
                border-top-left-radius: 28px;
                border-top-right-radius: 28px;
                position: absolute;
                top: -28px;
                left: calc(50% - 28px);
                background: #fff;
            }
        }

        .member_heading {
            font-size: 26px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: #EE7830;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .guest_heading {
            font-size: 26px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: #25272D;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .btn_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            gap: 16px;
        }

        .member_btn {
            max-width: 555px;
            padding: 16px;
        }

        .guest_btn {
            max-width: 555px;
            color: #25272D;
            width: 100%;
            padding: 16px;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            font-size: 16px;

            &:hover {
                background: #8f939a3c;
            }
        }
    }

    .compare_container {
        display: block;

        @media screen and (max-width: 900px) {
            display: none;
        }
    }

    .compare_container_resp {
        display: none;

        @media screen and (max-width: 900px) {
            display: block;
        }
    }

    .resp_table {
        .header_resp {
            margin-top: 50px;
            margin-bottom: 34px;
            display: flex;
            justify-content: space-between;
            padding: 0px 8px;
            align-items: center;

            .member_heading {
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                text-align: center;
                color: #EE7830;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .guest_heading {
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                text-align: center;
                color: #25272D;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .content_comparison {
            padding: 8px 0px;
            border-bottom: 1.5px solid #F3F3F3;
            margin-bottom: 24px;

            .name_resp {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                color: #6C6D6F;
            }

            .feature {
                font-size: 12px;
                font-weight: 500;
                line-height: 32px;
                color: #25272D;
            }

        }

    }

    .plans_container {
        width: 100%;
        z-index: 0;

        .plans_wrapper {
            max-width: 555px;
            margin: 0px auto;

            .subscription-card {
                .plan-details {
                    width: 100%;
                    padding: 48px 20px 20px 20px;

                    @media screen and (max-width:'600px') {
                        padding: 40px 20px 28px 20px;
                    }
                }
            }

            .feature_heading {
                position: absolute;
                border: 1px solid #DCDCDC;
                border-radius: 12px;
                height: 26px;
                width: 138px;
                font-size: 10px;
                font-weight: 500;
                line-height: 35px;
                color: #25272D;
                margin-bottom: 0px;
                left: calc(50% - 69px);
                top: -14px;
                z-index: 1;
                background-color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .feature-item {
                font-size: 14px;
                font-weight: 500;
                line-height: 34.5px;
                color: #25272D;

            }
        }



    }

}