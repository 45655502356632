.time_slots_wrapper_outer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 768px) {
        gap: 12px;
    }
}

.date_wise_wrapper {
    width: 100%;

    .date_wise_header {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
        background: #fff4ee;
        cursor: pointer;
        padding: 8px 12px 8px 24px;

        @media screen and (max-width: 768px) {
            margin-left: -20px;
            width: 100dvw;
            padding: 8px 20px;
        }
    }

    .date_wise_date_count {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #25272d;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .date_wise_date_count_resp {
        display: none;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        color: #25272d;

        @media screen and (max-width: 768px) {
            display: flex;
        }
    }

    .date_wise_header_action {
        display: flex;
        align-items: center;
        gap: 16px;

        @media screen and (max-width: 768px) {
            gap: 8px;
        }

        .date_wise_add_slot {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #ee7830;
            display: flex;
            align-items: center;
            gap: 8px;

            &:hover {
                text-decoration: underline;
            }

            @media screen and (max-width: 768px) {
                font-size: 12px;
            }
        }

        .collapse_icon {
            width: 24px;
            height: 24px;
        }
    }

    .time_slots_section {
        padding: 24px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .slots_mapping_container {
    }

    .slots_mapping_inner {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 16px;

        .time_slots_section_left {
            display: flex;
            flex-grow: 1;
            align-items: center;
            gap: 12px;
        }

        .time_slots_section_right {
            width: 170px;
            display: flex;
            align-items: center;
            gap: 16px;

            .time_slots_result {
                flex-grow: 1;
            }

            .remove_slot_icon {
                width: 32px;
                height: 32px;
                cursor: pointer;
            }

            @media screen and (max-width: 768px) {
                .time_slots_result {
                    display: none;
                }

                .remove_slot_icon {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                }

                width: fit-content;
            }
        }
    }

    .resp_duration {
        display: none;
        align-items: center;
        gap: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        color: #25272d;

        @media screen and (max-width: 768px) {
            display: flex;
        }
    }
}

.bottom_slots_container {
    width: 100%;
    height: 1px;
    background: #dcdcdc;
    margin-top: 12px;
    margin-bottom: 12px;
    position: relative;

    .add_slot_btn {
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 53px);
        background: #ffffff;
        padding: 0px 9px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #ee7830;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        &:hover {
            border-radius: 8px;
            text-decoration: underline;
        }
    }

    @media screen and (max-width: 768px) {
        margin-left: -20px;
        width: 100dvw;
    }
}
