.footer_partner_layout {
  width: 100% !important;
  max-width: none !important;
  box-sizing: border-box;
  padding: 0px 40px !important;
  max-width: 1200px !important;

  @media screen and (max-width: 600px) {
    padding: 0px !important;
    margin: 0px !important;
    height: 100dvh;
    align-items: flex-end !important;
  }

  .modal-content {
    background: #ffffff;
    border-radius: 0;
    border: 0;

    @media screen and (max-width: 600px) {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      max-height: 90dvh;
      overflow-y: auto;
    }

    .modal-body {
      padding: 52px 64px;

      @media screen and (max-width: 600px) {
        padding: 20px;
      }

      .footer_pop_container {
        gap: 30px;

        @media screen and (max-width: 800px) {
          flex-direction: column;
          gap: 16px;
          margin-top: 40px;
        }

        .footer_pop_left {
          width: 53%;
          background: #25272d;
          border-radius: 8px;
          padding: 36px 32px;
          color: #fff;

          @media screen and (max-width: 800px) {
            width: 100%;
            background: transparent;
            border-radius: 0px;
            padding: 0px;
            color: #25272d;
          }

          .right_heading {
            font-size: 30px;
            font-weight: 600;
            line-height: 44.12px;
            text-align: left;
            display: inline;
          }

          .right_desc {
            margin-top: 5px;
            margin-bottom: 25px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;

            @media screen and (max-width: 800px) {
              color: #8f939a;
              margin-bottom: 16px;
            }
          }

          .contact_data {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
          }
        }

        .footer_pop_right {
          width: 47%;
          display: flex;
          flex-direction: column;
          gap: 22px;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 800px) {
            width: 100%;
          }

          .form_wrapper {
            max-width: 360px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;

            @media screen and (max-width: 600px) {
              max-width: 100%;
            }

            .right_heading {
              font-size: 22.58px;
              font-weight: 500;
              line-height: 33.86px;
              text-align: left;
              color: #25272d;
            }

            .footer_pop_submit {
              border-radius: 24px;
              padding: 9px 52px;
              width: fit-content;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: center;
            }

            .right_form_input {
              display: flex;
              flex-direction: column;
              gap: 14px;
            }
          }
        }
      }
    }
  }

  .input_text_footer {
    border: 1px solid #8f939a;
    border-radius: 5px;
    padding: 11.5px 13px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #25272d;
    width: 100%;
    resize: none;
    min-height: auto;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #8f939a;
    }
  }

  .input_error {
    margin-top: 8px;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: red;
  }

  .custom_input_error {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: rgb(220, 53, 69);
  }

  .file_input_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #8f939a;

    .input_click_container {
      display: flex;
      align-items: center;
      gap: 7px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .close_container {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;

    @media screen and (max-width: 600px) {
      // display: none;
    }
  }

  .right_heading {
    @media screen and (max-width: 600px) {
      display: flex;
      gap: 8px;
    }

    .res_close {
      display: none;
      height: fit-content;
      width: fit-content;
      margin-top: 8px;
      cursor: pointer;

      @media screen and (max-width: 600px) {
        display: flex;
      }
    }
  }
}