.customRadio-wrap {
    @media screen and (max-width: 480px) {
        width: 100%;
    }
    .MuiFormGroup-root {
        // column-gap: 16px;
        @media screen and (max-width: 480px) {
            width: 100%;
            justify-content: space-between;
        }
    }
    .MuiFormControlLabel-root {
        // margin-right: 0;
        @media screen and (max-width: 480px) {
            margin-right: 0;
        }
    }
    .MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        letter-spacing: 0;
    }
    .MuiButtonBase-root:not(.Mui-disabled) {
        color: #ee7830 !important;
    }

    .custom_radio_gap{
        &.MuiRadioGroup-root{
            .MuiRadio-root{
                padding: 4px 9px;
            }
        }
    }
}
