.qualification_modal {
    max-width: 619px;
    padding: 42px 32px 36px 32px;
    width: 100%;
    background: #ffffff;
    border-radius: 12px;
    display: grid;
    box-sizing: border-box;

    @media screen and (max-width: 700px) {
        padding: 40px 16px 36px 16px;
        margin: auto 16px;
        width: calc(100% - 32px);
    }

    .header_qualification {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;

        .title_header {
            flex-grow: 1;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            text-align: center;
            color: #25272D;

            @media screen and (max-width: 700px) {
                font-size: 20px;
                line-height: 24px;
                // flex-grow: initial;
            }
        }

        .close_q {
            cursor: pointer;

            @media screen and (max-width: 700px) {
                display: none;
            }
        }
    }

    .sub_header_qualification {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
        color: #6C6D6F;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .tabular_details {
        // display: flex;
        padding: 0px 17px;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;


        @media screen and (max-width:700px) {
            padding: 0px 4px;
        }

        .rows_details {
            display: flex;
            width: 100%;
            gap: 20px;
            border-bottom: 1px solid #DCDCDC;
            padding: 0px 17px 10px 0px;

            @media screen and (max-width:700px) {
                padding: 0px 8px 10px 0px;
            }

            .rows_qual {
                width: calc(100% - 210px);
                box-sizing: border-box;

                @media screen and (max-width:700px) {
                    width: calc(100% - 145px);
                }

                .rows_file {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    box-sizing: border-box;



                    .doc_img_q {
                        width: 11px;
                        height: 11px;
                    }

                    .file_name_q {
                        max-width: 250px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #25272D;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        @media screen and (max-width:700px) {
                            width: 80%;
                        }
                    }
                }
            }

            .rows_action {
                display: flex;
                width: 210px;
                align-items: center;
                gap: 34px;
                justify-content: flex-end;

                @media screen and (max-width:700px) {
                    width: 145px;
                    gap: 18px;
                }

                .btn_down_q {
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #EE7830;

                    .btn_img_q {
                        width: 16px;
                        height: 16px;
                    }

                    &.btn_preview_q {
                        @media screen and (max-width:700px) {
                            span {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .button_option {
        width: 100%;
        margin-top: 38px;

        .go_btn_back {
            padding: 16px 16px;
            background: #EE7830;
            border: none;
            border-radius: 12px;
            cursor: pointer;
            box-shadow: 0px 4px 32px 0px #00000014;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            gap: 4px;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: #fff;
            transition: all 0.3s ease-in;
            box-sizing: border-box;

            &:hover {
                background: #ee7930da;
            }
        }
    }
}