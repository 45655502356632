.package_schedule_dates_layout {
    min-height: calc(100dvh - 56px);
    width: 100%;

    @media screen and (max-width:768px) {
        min-height: calc(100dvh - 50px);
    }

    .package_schedule_body_wrapper {
        min-height: calc(100dvh - 56px - 146px);
        overflow-y: auto;
        width: 100%;
        padding-bottom: 170px;
        position: relative;

        @media screen and (max-width:768px) {
            min-height: calc(100dvh - 50px - 128px);
            padding-bottom: 152px;
        }

        @media screen and (max-width:386px) {
            min-height: calc(100dvh - 50px - 144px);
            padding-bottom: 168px;
        }

        .package_schedule_back_arrow_container {
            position: absolute;
            top: 40px;
            left: 80px;
            width: fit-content;
            display: flex;
            gap: 10px;
            align-items: flex-start;
            cursor: pointer;

            @media screen and (max-width:1000px) {
                top: 24px;
                left: 20px;
                // display: none;
            }

            .package_arrow_icon_back {
                width: 24px;
                height: 24px;

                @media screen and (max-width:768px) {
                    width: 20px;
                    height: 20px;
                }
            }

            .package_back_btn_arrow_text {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: #6C6D6F;

                @media screen and (max-width:1000px) {
                    display: none;
                }
            }
        }

        .package_schedule_header_wrapper {
            width: 100%;
            display: flex;
            padding: 40px 280px 18px 280px;
            justify-content: center;
            gap: 24px;

            @media screen and (max-width:1000px) {
                padding: 24px 20px 18px 20px;
                gap: 4px;
            }

            .package_schedule_header_data {
                display: flex;
                flex-direction: column;
                gap: 2px;

                .package_schedule_header_heading {
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 32px;
                    text-align: center;
                    width: 100%;
                    color: #25272D;

                    @media screen and (max-width:768px) {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                    }
                }

                .package_schedule_header_subheading {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: center;
                    width: 100%;
                    color: #6C6D6F;

                    @media screen and (max-width:768px) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            .package_schedule_steps_container {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                color: #25272D;

                .package_responsive_steps {
                    display: none;
                }

                @media screen and (max-width:768px) {
                    .package_responsive_steps {
                        display: flex;
                    }

                    .package_web_steps {
                        display: none;
                    }
                }
            }


        }

        .package_schedule_content_wrapper {
            width: 100%;
            display: flex;
            justify-content: center;

            @media screen and (max-width:768px) {
                padding: 0px 20px;
            }

            .package_schedule_content_inner {
                width: 100%;
                max-width: 546px;
            }

            .package_schedule_content_meet_link {
                width: 100%;
                max-width: 900px;
            }

            .package_schedule_content_time {
                width: 100%;
                max-width: 800px;
            }


            .package_event_schedule_selection {
                height: 60px;
                padding: 0px 43px 0px 12px;
                border: 1px solid #DCDCDC;
                border-radius: 10px;
                width: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                background: #FFFFFF;

                .filled_label {
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 14px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    position: absolute;
                    top: -7px;
                    left: 12px;
                    padding: 0px 4px;
                    background: #FFFFFF;
                }

                .no_package_schedule {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #6C6D6F;

                    &.obtained_data {
                        color: #25272D;
                    }
                }

                .schedule_package_event_icon {
                    position: absolute;
                    right: 16px;
                    top: calc(50% - 12px);
                }

                &:hover {
                    background: #F3F3F3;
                }
            }


        }
    }

    .package_schedule_footer_wrapper {
        width: 100%;
        height: 146px;
        padding: 45px 0px;
        position: fixed;
        bottom: 0;
        box-shadow: 0px 0px 4px 0px #00000040;
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        z-index: 5;

        @media screen and (max-width:768px) {
            padding: 22px 20px;
            box-shadow: 0px 4px 32px 0px #25272D3D;
            height: fit-content;
            gap: 12px;
        }

        .package_schedule_footer_action {
            width: 100%;
            max-width: 546px;
            display: flex;
            gap: 24px;

            @media screen and (max-width:768px) {
                gap: 14px;
            }

            .package_add_event_btn_container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }

        }

        // .package_schedule_footer_info {
        //     width: 100%;
        //     max-width: 546px;
        //     font-size: 14px;
        //     font-weight: 400;
        //     line-height: 16px;
        //     text-align: center;
        //     color: #6C6D6F;
        // }
    }
}