.customNumberInput {
    position: relative;
    .label {
        position: absolute;
        font-size: 10px;
        background: white;
        left: 10px;
        top: -7px;
        padding: 0 3px;
    }
}
