.all_dates_dialog_modal {

    max-width: 734px !important;


    .all_event_dates_body {

        padding: 36px 64px !important;



        .all_event_dates_text {
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            text-align: center;
            color: rgba(37, 39, 45, 1);
            width: calc(100% - 24px);
            padding-left: 16px;
        }

        .dismiss_container {
            width: 16px;
        }

        .sub_heading_all_events {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: rgba(143, 147, 154, 1);
        }

        .dates_cards_container {
            margin-top: 32px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
            max-height: 380px;
            overflow-y: scroll;
        }

        .dates_cards {
            border: 2px solid rgba(243, 243, 243, 1);
            border-radius: 12px;
            padding: 8px 16px;
            display: flex;
            flex-direction: column;
            gap: 12px;

            .dates_cards_date {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: rgba(37, 39, 45, 1);
            }

            .dates_cards_timing {
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
                color: rgba(143, 147, 154, 1);
                display: flex;
                align-items: center;
                gap: 8px;
            }

            .dates_cards_spots {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: rgba(143, 147, 154, 1);

                span {
                    color: rgba(37, 39, 45, 1);
                }
            }
        }

        @media screen and (max-width: "768px") {
            padding: 24px 32px !important;

            .dates_cards_container {
                grid-template-columns: repeat(2, 1fr);
            }

        }

        @media screen and (max-width: "500px") {
            padding: 20px 24px !important;

            .dates_cards_container {
                grid-template-columns: repeat(1, 1fr);
            }

        }

    }

    @media screen and (max-width: "578px") {
        width: 100%;
        margin: 0 !important;
        background-color: transparent;
        max-width: 734px !important;
    }
}