.pagination_container {

    .pagination {
        display: flex;
        padding-left: 0;
        list-style: none;
        align-items: center;
    }

    .page-item {
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
            background: #EE7830;
            min-width: 30px;
            height: 30px;
            border-radius: 5px;
        }

        &.disabled {

            background: none;
            cursor: not-allowed;
            pointer-events: auto;

        }
    }

    .prev_page_container,
    .next_page_container {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #25272D;

        @media screen and (max-width:600px) {
            span {
                display: none;
            }
        }

        &.disabled {
            cursor: not-allowed;
        }
    }
}