.event_container_outer {
    width: 100%;
    display: grid;
    margin-bottom: 15px;
    grid-template-columns: repeat(4, minmax(320px, 1fr));
    gap: 16px;

    @media screen and (max-width:1850px) {
        grid-template-columns: repeat(3, minmax(320px, 1fr));
    }

    @media screen and (max-width:1520px) {
        grid-template-columns: repeat(3, minmax(280px, 1fr));
    }

    @media screen and (max-width:1400px) {
        grid-template-columns: repeat(3, minmax(250px, 1fr));
    }

    @media screen and (max-width:1300px) {
        grid-template-columns: repeat(2, minmax(250px, 1fr));
    }

    @media screen and (max-width:1024px) {
        grid-template-columns: repeat(3, minmax(250px, 1fr));
    }

    @media screen and (max-width:800px) {
        grid-template-columns: repeat(2, minmax(250px, 0.5fr));
    }

    @media screen and (max-width:560px) {
        grid-template-columns: repeat(1, minmax(250px, 1fr));
    }

}

.no_data_container {
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .no_data_img {
        width: 280px;
        height: 180px;
    }

    .no_data_heading {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    .no_data_content {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }


    @media screen and (max-width:560px) {
        .no_data_img {
            width: 280px;
            height: 180px;
        }
    }
}