.file_preview_container {
    .modal-content {

        background: rgba(37, 39, 45, 0.4);
    }

    .modal-body {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 !important;
        padding: 0 !important;
    }

    .file_preview_wrap {
        .file_preview_close {
            position: absolute;
            right: 24px;
            top: 24px;
            z-index: 2;
            width: 40px;
            height: 40px;
        }

        .custom_file_preview {
            width: 100%;
            height: auto;
            max-height: 100%;
            max-width: 100%;
            object-fit: cover;
        }





    }
}