.payment_layout {
    height: 100dvh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #25272D;

    .logo_container_pay {
        position: absolute;
        top: 16px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 12px;
    }
}