.custom-single-select {
    position: relative;
    background-image: url(../../assets/img/downarrow.svg);
    background-repeat: no-repeat;
    background-position: 95% 25px;

    .form_group {
        margin: 0;
        .input_box {
            input {
                padding: 17px 12px;
            }
        }
        .disable{
            color: rgba(0, 0, 0, 0.38);
        }
    }

    .frequency_option {
        background: #fff;
        list-style: none;
        padding: 16px;
        background: #ffffff;
        box-shadow: 4px 4px 20px 0px #0000001a;
        margin-top: 8px;
        position: absolute;
        width: 100%;
        border-radius: 12px;
        border: 1px solid #f3f3f3;

        li {
            text-transform: capitalize;
            text-align: left;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            padding: 8px 0 8px 12px;
            cursor: pointer;
            color: #8f939a;
            text-align: center;

            // border-bottom: 1px solid #f3f3f3;
            &:hover {
                background-color: #f6f6f7;
            }

            &.active {
                // background: url(../../assets/img/checked.svg);
                background-repeat: no-repeat;
                background-position: 95% center;
                cursor: not-allowed;
            }

            &:last-child {
                border: none;
            }
        }

        &.selection {
            li {
                color: #25272d;
                text-align: left;
                font-weight: 600;
            }
        }
    }

    &.month_frequency {
        max-width: 375px;

        li {
            border-radius: 4px;

            &.active {
                background-color: #ee772f;
                color: #fff;
                background-image: url(../../assets/img/whitecheck.svg);
            }
        }
    }
}
