.interest_heading {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #25272D;
}



.interest_btn_wrap {
    border: 1px solid #DCDCDC;
    padding: 18px 20px;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 12px;

    &:hover {
        background-color: #25272d0c;
    }

    .select_payout_heading {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #EE762F;
    }
}

.interest_list {
    display: flex;
    gap: 8px;
    margin-top: 10px;

    .list_chip {
        border: 1px solid #EE762F;
        padding: 7px 12px;
        border-radius: 16px;
        display: flex;
        gap: 6px;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        color: #25272D;
        height: fit-content;


        &.button_chip {
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                background: #EE762F;
                color: #fff;
            }
        }

        &.special_w_limit {

            .chip_title {
                max-width: 75px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @media screen and (max-width:420px) {
                    max-width: 60px;
                }

                @media screen and (max-width:390px) {
                    max-width: 50px;
                }

            }

        }
    }

    img {
        width: 16px;
        height: 16px;
    }
}

.interest_btn_wrap-2 {
    background-color: #fff !important;
}

.interest_btn_wrap-2 :hover {
    background-color: #fff !important;
}