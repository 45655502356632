.schedule_dates_layout {
    min-height: calc(100dvh - 56px);
    width: 100%;

    @media screen and (max-width:768px) {
        min-height: calc(100dvh - 50px);
    }

    .schedule_body_wrapper {
        min-height: calc(100dvh - 56px - 146px);
        overflow-y: auto;
        width: 100%;
        padding-bottom: 170px;

        @media screen and (max-width:768px) {
            min-height: calc(100dvh - 50px - 128px);
            padding-bottom: 152px;
        }

        @media screen and (max-width:386px) {
            min-height: calc(100dvh - 50px - 144px);
            padding-bottom: 168px;
        }

        .schedule_header_wrapper {
            width: 100%;
            display: flex;
            padding: 40px 80px 18px 80px;
            justify-content: space-between;
            gap: 24px;

            @media screen and (max-width:768px) {
                padding: 24px 20px 18px 20px;
                gap: 4px;
            }

            .schedule_header_data {
                // width: 100%;
                // max-width: 546px;
                display: flex;
                flex-direction: column;
                gap: 2px;

                .schedule_header_heading {
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 32px;
                    text-align: center;
                    width: 100%;
                    color: #25272D;

                    &.package_limit_heading {
                        max-width: 624px;
                    }

                    @media screen and (max-width:768px) {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                    }
                }

                .schedule_header_subheading {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: center;
                    width: 100%;
                    color: #6C6D6F;

                    @media screen and (max-width:768px) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }

            }

            .schedule_steps_container {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                color: #25272D;

                .responsive_steps {
                    display: none;
                }

                .web_steps {
                    display: flex;
                    width: max-content;
                }

                @media screen and (max-width:768px) {
                    .responsive_steps {
                        display: flex;
                        width: max-content;
                    }

                    .web_steps {
                        display: none;
                    }
                }
            }

            .schedule_back_arrow_container {
                width: fit-content;
                display: flex;
                gap: 10px;
                align-items: flex-start;
                cursor: pointer;

                .arrow_icon_back {
                    width: 24px;
                    height: 24px;

                    @media screen and (max-width:768px) {
                        width: 20px;
                        height: 20px;
                    }
                }

                .back_btn_arrow_text {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #6C6D6F;

                    @media screen and (max-width:768px) {
                        display: none;
                    }
                }
            }
        }

        .schedule_content_wrapper {
            width: 100%;
            display: flex;
            justify-content: center;

            @media screen and (max-width:768px) {
                padding: 0px 20px;
            }

            .schedule_content_inner {
                width: 100%;
                max-width: 546px;
            }

            .schedule_content_meet_link {
                width: 100%;
                max-width: 900px;
            }

            .schedule_content_time {
                width: 100%;
                max-width: 800px;
            }
        }

    }

    .schedule_footer_wrapper {
        width: 100%;
        height: 146px;
        padding: 30px 0px;
        position: fixed;
        bottom: 0;
        box-shadow: 0px 0px 4px 0px #00000040;
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        z-index: 5;

        @media screen and (max-width:768px) {
            padding: 22px 20px;
            box-shadow: 0px 4px 32px 0px #25272D3D;
            height: fit-content;
            gap: 12px;
        }



        .schedule_footer_action {
            width: 100%;
            max-width: 546px;
            display: flex;
            gap: 24px;

            @media screen and (max-width:768px) {
                gap: 14px;
            }


            .cancel_scheduling_btn {
                width: calc(45% - 24px);
                cursor: pointer;
                padding: 16px;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                color: #25272D;
                border-radius: 12px;

                &:hover {
                    background: #00000040;
                }

                @media screen and (max-width:768px) {
                    width: 134px;

                    .non_resp_btn_text {
                        display: none;
                    }
                }
            }

            .add_time_slots_btn_container {
                width: calc(55%);

                .add_time_slots_btn {
                    height: 56px;
                }

                @media screen and (max-width:768px) {
                    width: calc(100% - 148px);
                }

            }

            .save_limit_btn_container {
                width: 344px;
                margin: 0px auto;

                .add_time_slots_btn {
                    height: 56px;
                }

                @media screen and (max-width: 600px) {
                    width: 100%;
                }

            }

        }

        .schedule_footer_info {
            width: 100%;
            max-width: 546px;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            color: #6C6D6F;
        }
    }
}