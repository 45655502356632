.special_inst_wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .special_inst_heading_wrapper {
        display: flex;
        align-items: center;
        gap: 24px;
        justify-content: space-between;
        width: 100%;



        .title_special_inst {

            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: #25272D;
        }

        .add_special_btn_wrapper {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            cursor: pointer;

            .disable_text {
                color: #6C6D6F;
                cursor: not-allowed;
            }

            .active_text {
                color: #EE7830;
            }

            &:hover .active_text {
                text-decoration: underline;
            }

            .add_special_btn {
                width: 24px;
                height: 24px;
            }
        }
    }

    .input_special_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .add_inst_wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 16px;
            position: relative;

            .add_inst_input {
                width: calc(100% - 36px);
                position: relative;
            }

            .special_delete_icon {
                cursor: pointer;
            }

            .form_group {
                margin: 0;
            }

            textarea {
                height: 44px !important;
                min-height: 44px !important;
                padding: 9px 40px 9px 12px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }

            textarea::-webkit-scrollbar {
                width: 2px;
            }

            .count_inst {
                position: absolute;
                right: 0;
                top: 50px;
                font-size: 10px;
                font-weight: 400;
                line-height: 14px;
                color: #6C6D6F;
                text-align: right;
            }

            .cross_icon_inst {
                position: absolute;
                top: 10px;
                right: 16px;
                cursor: pointer;
            }
        }
    }

}