.media_wrapper {
    position: relative;

    .event_img {}


    .media_preview {
        width: 40px;
        height: 40px;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        cursor: pointer;
        z-index: 2;
    }



    .media_loading_wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;

        &.not_supported {
            color: #fff;
            width: 100%;
        }

        .media_loading {
            width: 20px;
            height: 20px;
            z-index: 3;
        }
    }
}