.interest_container_full {
    padding: 0px 98px;

    @media screen and (max-width:600px) {
        padding: 0px 20px;
    }

    .heading_listing_interest {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        text-align: center;
        color: #25272D;
        margin: 40px 0px;

        @media screen and (max-width:600px) {
            margin: 20px 0px;
            font-size: 20px;
            line-height: 24px;
        }
    }
}